import { Fragment, React, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ChatBot from "../pages/ChatBot";
import Logo from "../images/LogoT.ico";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import firebaseConfig from "../data/firebaseConfig";
import Registration from "../pages/registration";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const navigation = [
  { name: "Home", href: "/", current: false },
  // { name: "Navigation", href: "navigation", current: false },
  { name: "Tourist spots", href: "places", current: false },
  { name: "Delicacies", href: "delicacies", current: false },
  { name: "Accomodation", href: "accomodation", current: false },
  { name: "P.U.V Terminals", href: "terminal", current: false },
  { name: "Festivities", href: "festivities", current: false },
];

const subAdmin = [
  { name: "Delicacies Form", href: "delicaciesForm", current: false },
  { name: "Accommodation Form", href: "accommodationForm", current: false },
];

const moreNavigation = [
  { name: "Admin", href: "admin", current: false },
  { name: "Approval", href: "approval", current: false },
];

const userNavigation = [{ name: "Your Profile", href: "login" }];

const loginNavigation = [{ name: "Register", href: "registration" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Topnavbar() {
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "Guest",
    email: "Guest@gmail.com",
    imageUrl: "https://cdn-icons-png.flaticon.com/128/1999/1999625.png",
  });

  const [subAdminApproved, setSubAdminApproved] = useState(false);
  const [type, setType] = useState('');

  // Function to open the registration modal
  const openRegistrationModal = () => {
    setIsRegistrationOpen(true);
  };

  // Function to close the registration modal
  const closeRegistrationModal = () => {
    setIsRegistrationOpen(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        const userDocRef = doc(db, "users", user.uid);

        getDoc(userDocRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setIsAdmin(userData.isAdmin || false);

              // Now, fetch the username and email from the "userData" document
              const userDataRef = doc(
                db,
                "users",
                user.uid,
                "userData",
                "document"
              );
              getDoc(userDataRef)
                .then((userDataSnapshot) => {
                  if (userDataSnapshot.exists()) {
                    const userData = userDataSnapshot.data();
                    setUser({
                      name: userData.username || "Guest",
                      email: userData.email || "Guest@gmail.com",
                    });
                  }
                })
                .catch((error) => {
                  console.error("Error getting userData document:", error);
                });

              // Get the user's profile picture from Firebase Storage
              const storageRef = ref(
                storage,
                `user-profile-photos/${user.uid}`
              );
              getDownloadURL(storageRef)
                .then((downloadURL) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    imageUrl: downloadURL,
                  }));
                })
                .catch((error) => {
                  console.error("Error getting profile photo:", error);
                });

              // Check if the user is subAdmin with approved status
              const userRegistrationRef = doc(db, "registrations", user.uid);

              getDoc(userRegistrationRef)
                .then((docSnapshot) => {
                  if (docSnapshot.exists()) {
                    const registrationData = docSnapshot.data();
                    if (
                      registrationData &&
                      registrationData.userEmail === user.email &&
                      registrationData.status === "approved"
                    ) {
                      // User is subAdmin with approved status
                      setSubAdminApproved(true);
                      setType(registrationData.type || '');
                    } else {
                      // User is not subAdmin or not approved
                      setSubAdminApproved(false);
                    }
                  } else {
                    // No registration data found
                    setSubAdminApproved(false);
                  }
                })
                .catch((error) => {
                  console.error("Error fetching registration data:", error);
                });
            }
          })
          .catch((error) => {
            console.error("Error getting user document:", error);
          });
      } else {
        setIsLoggedIn(false);
        setUser({
          name: "Guest",
          email: "Guest@gmail.com",
          imageUrl: "https://cdn-icons-png.flaticon.com/128/1999/1999625.png",
        });
        setIsAdmin(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      navigate("/login"); // Redirect to the /profile route
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <>
      <div className="fixed w-full z-10">
        <Disclosure as="nav" className="bg-blue-600">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-8 w-8" src={Logo} alt="Touristara" />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-2">
                        {navigation.map((item) => (
                          <Link
                            style={{ textDecoration: "none" }}
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              // Set the clicked item as current
                              navigation.forEach(
                                (navItem) =>
                                  (navItem.current = navItem.name === item.name)
                              );

                              // Set other items as not current
                              navigation.forEach((navItem) => {
                                if (navItem.name !== item.name) {
                                  navItem.current = false;
                                }
                              });
                            }}
                            className={classNames(
                              item.current
                                ? "bg-blue-900 text-white"
                                : "text-gray-300 hover-bg-blue-700 hover-text-white",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                        {subAdminApproved && (
                          <>
                            {type === "Accommodation" &&
                              subAdmin.map(
                                (item) =>
                                  item.name === "Accommodation Form" && (
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      key={item.name}
                                      to={item.href}
                                      onClick={() => {
                                        // Set the clicked item as current
                                        subAdmin.forEach(
                                          (navItem) =>
                                            (navItem.current =
                                              navItem.name === item.name)
                                        );

                                        // Set other items as not current
                                        subAdmin.forEach((navItem) => {
                                          if (navItem.name !== item.name) {
                                            navItem.current = false;
                                          }
                                        });
                                      }}
                                      className={classNames(
                                        item.current
                                          ? "bg-blue-900 text-white"
                                          : "text-gray-300 hover-bg-blue-700 hover-text-white",
                                        "rounded-md px-3 py-2 text-sm font-medium"
                                      )}
                                      aria-current={
                                        item.current ? "page" : undefined
                                      }
                                    >
                                      {item.name}
                                    </Link>
                                  )
                              )}

                            {type === "Delicacies" &&
                              subAdmin.map(
                                (item) =>
                                  item.name === "Delicacies Form" && (
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      key={item.name}
                                      to={item.href}
                                      onClick={() => {
                                        // Set the clicked item as current
                                        subAdmin.forEach(
                                          (navItem) =>
                                            (navItem.current =
                                              navItem.name === item.name)
                                        );

                                        // Set other items as not current
                                        subAdmin.forEach((navItem) => {
                                          if (navItem.name !== item.name) {
                                            navItem.current = false;
                                          }
                                        });
                                      }}
                                      className={classNames(
                                        item.current
                                          ? "bg-blue-900 text-white"
                                          : "text-gray-300 hover-bg-blue-700 hover-text-white",
                                        "rounded-md px-3 py-2 text-sm font-medium"
                                      )}
                                      aria-current={
                                        item.current ? "page" : undefined
                                      }
                                    >
                                      {item.name}
                                    </Link>
                                  )
                              )}
                          </>
                        )}
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <button
                              onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
                              type="button"
                              className={`rounded-md text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 text-sm font-medium focus:outline-none ${
                                isAdmin ? "" : "hidden"
                              }`} // Add hidden class if not admin
                              id="options-menu"
                              aria-haspopup="true"
                              aria-expanded="true"
                            >
                              More
                            </button>
                          </div>

                          <Transition
                            show={isMoreMenuOpen}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {moreNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      to={item.href}
                                      className={classNames(
                                        active ? "bg-blue-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-blue-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img
                              src={
                                user.imageUrl ||
                                "https://cdn-icons-png.flaticon.com/128/1999/1999625.png"
                              }
                              alt="User Profile"
                              className="w-10 h-10 rounded-full"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-blue-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                            {/* Logout button */}
                            {isLoggedIn && (
                              <>
                                {loginNavigation.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <Link
                                        onClick={openRegistrationModal}
                                        style={{ textDecoration: "none" }}
                                        className={classNames(
                                          active ? "bg-blue-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {item.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={handleLogout}
                                      type="button"
                                      className={classNames(
                                        active ? "bg-blue-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      Log Out
                                    </button>
                                  )}
                                </Menu.Item>
                              </>
                            )}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>

                    {/* Registration Modal */}
                    <Registration
                      open={isRegistrationOpen}
                      handleClose={closeRegistrationModal}
                    />
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-blue-800 p-2 text-gray-400 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="md:hidden">
                <div
                  className="space-y-1 px-2 pb-3 pt-2 sm:px-3"
                  style={{ textAlign: "center", alignItems: "center" }}
                >
                  {navigation.map((item) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      key={item.name}
                      as="div"
                      to={item.href}
                      onClick={() => {
                        // Set the clicked item as current
                        navigation.forEach(
                          (navItem) =>
                            (navItem.current = navItem.name === item.name)
                        );

                        // Set other items as not current
                        navigation.forEach((navItem) => {
                          if (navItem.name !== item.name) {
                            navItem.current = false;
                          }
                        });
                      }}
                      className={classNames(
                        item.current
                          ? "bg-blue-900 text-white"
                          : "text-gray-300 hover-bg-blue-700 hover-text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Menu as="div" className="relative inline-block text-center">
                    <div>
                      <button
                        onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
                        type="button"
                        className={`rounded-md text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 text-sm font-medium focus:outline-none ${
                          isAdmin ? "" : "hidden"
                        }`} // Add hidden class if not admin
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        More
                      </button>
                    </div>
                    <Transition
                      show={isMoreMenuOpen}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {moreNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                style={{ textDecoration: "none" }}
                                to={item.href}
                                className={classNames(
                                  active ? "bg-blue-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        src={
                          user.imageUrl ||
                          "https://cdn-icons-png.flaticon.com/128/1999/1999625.png"
                        }
                        alt="User Profile"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user.email}
                      </div>
                    </div>
                    {/* <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                  </div>
                  <div
                    className="mt-3 space-y-1 px-2"
                    style={{ textAlign: "center", alignItems: "center" }}
                  >
                    {userNavigation.map((item) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        key={item.name}
                        as="a"
                        to={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-blue-700 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    ))}
                    {isLoggedIn && ( // Conditionally render the Log out button
                      <>
                        {loginNavigation.map((item) => (
                          <Link
                            style={{ textDecoration: "none" }}
                            key={item.name}
                            as="a"
                            onClick={openRegistrationModal}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-blue-700 hover:text-white"
                          >
                            {item.name}
                          </Link>
                        ))}
                        <br></br>
                        <button
                          onClick={handleLogout}
                          type="button"
                          className="relative rounded-full bg-blue-800 p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="absolute -inset-2" />
                          <span className="sr-only">Log out</span>
                          <span className="text-sm font-medium">Log Out</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <main className="h-screen overflow-y-auto">
          <div>
            {/* Your content */}
            <Outlet />
            <ChatBot />
          </div>
        </main>
      </div>
    </>
  );
}
