import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
// import chatbotData from "../data/updatedChatbotResponsed.json";
import chatbotTheme from "../pages/ChatBotTheme";
import logo from "../images/Botlogo.png";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import firebaseConfig from "../data/firebaseConfig";

const app = initializeApp(firebaseConfig);

function Chatbot() {
  const [chatbotData, setChatbotData] = useState([]);

  const config = {
    botAvatar: logo,
    floating: true,
  };

  useEffect(() => {
    const db = getDatabase(app);
    const chatbotRef = ref(db, "chatbot"); // Replace 'chatbot' with your Firebase database reference

    // Fetch data from Firebase Realtime Database
    onValue(chatbotRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Update state with fetched data
        setChatbotData(data);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={chatbotTheme}>
      {chatbotData.length > 0 && (
        <ChatBot headerTitle="TourBot" steps={chatbotData} {...config} />
      )}
    </ThemeProvider>
  );
}

export default Chatbot;
