const firebaseConfig = {
  apiKey: "AIzaSyCHk3nVcC_ix3X2Ip8OTaMjLwfvvIDy-wQ",
  authDomain: "touristaraa.firebaseapp.com",
  databaseURL: "https://touristaraa-default-rtdb.asia-southeast1.firebasedatabase.app", 
  projectId: "touristaraa",
  storageBucket: "touristaraa.appspot.com",
  messagingSenderId: "817399807581",
  appId: "1:817399807581:web:9331b0018e0184a58597b4"
};

export default firebaseConfig;