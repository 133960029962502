import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material"; // Import CircularProgress for loading indicator
import { getAuth, onAuthStateChanged } from "firebase/auth";

function withAuth(Component) {
  return function AuthenticatedComponent(props) {
    const [user, setUser] = useState(null);
    const [authChecked, setAuthChecked] = useState(false); // State for checking if authentication is complete

    useEffect(() => {
      const auth = getAuth();
      const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
        setUser(user);
        setAuthChecked(true); // Set authChecked to true when authentication check is complete
      });

      return () => {
        unsubscribeAuth();
      };
    }, []);

    if (!authChecked) {
      // While checking authentication, show a loading indicator
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <CircularProgress />
        </div>
      );
    }

    if (user === null) {
      // If user is not authenticated, render an empty div
      return <div />;
    }

    // If authenticated, render the provided component
    return <Component {...props} />;
  };
}

export default withAuth;


