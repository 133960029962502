import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../data/firebaseConfig";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import useAuth from "../ProtectedRoute/useAuth";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

function Approval() {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [displayOption, setDisplayOption] = useState("forApproval"); // State to manage what to display
  const [registrationData, setRegistrationData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedRegistrationId, setSelectedRegistrationId] = useState(null);
  const { adminPlace } = useAuth();

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleDelete = (registrationId) => {
    setSelectedRegistrationId(registrationId);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    setSelectedRegistrationId(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedRegistrationId) {
      try {
        const registrationRef = doc(
          db,
          "registrations",
          selectedRegistrationId
        );
        await deleteDoc(registrationRef);
      } catch (error) {
        console.error("Error deleting registration:", error);
      } finally {
        handleConfirmationDialogClose();
      }
    }
  };

  useEffect(() => {
    async function fetchRegistrationData() {
      try {
        const registrationCollectionRef = collection(db, "registrations");
        const querySnapshot = await getDocs(registrationCollectionRef);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRegistrationData(data);

        const unsubscribe = onSnapshot(
          registrationCollectionRef,
          (snapshot) => {
            const updatedData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setRegistrationData(updatedData);
          }
        );

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching registration data:", error);
      }
    }

    fetchRegistrationData();
  }, [db]);

  const handleApproval = async (registrationId) => {
    try {
      const registrationRef = doc(db, "registrations", registrationId);
      await updateDoc(registrationRef, {
        status: "approved",
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleDeclined = async (registrationId) => {
    try {
      const registrationRef = doc(db, "registrations", registrationId);
      await updateDoc(registrationRef, {
        status: "declined",
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="m-4 text-center">
      <h2 className="text-2xl font-bold mb-4">Approval</h2>
      <div className="flex mb-4 space-x-2">
        {/* Buttons */}
        <button
          className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={() => setDisplayOption("forApproval")}
        >
          Pending
        </button>
        <button
          className="flex-1 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
          onClick={() => setDisplayOption("approved")}
        >
          Approved
        </button>
        <button
          className="flex-1 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
          onClick={() => setDisplayOption("declined")}
        >
          Declined
        </button>
      </div>

      {/* Conditional content based on button click */}
      {displayOption === "approved" && (
        <div className="font-semibold mt-4">
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {registrationData
              .filter(
                (registration) =>
                  registration.status === "approved" &&
                  registration.municipality === adminPlace // Filter by municipality
              )
              .map((registration) => (
                <div
                  key={registration.id}
                  className="bg-white p-3 shadow-md rounded-md"
                >
                  <div className="flex justify-between">
                    <p>FROM:</p>
                    <DeleteIcon
                      onClick={() => handleDelete(registration.id)}
                      className="text-red-500 hover:text-red-600 cursor-pointer"
                    />
                  </div>
                  <p className="mb-4 font-bold">{registration.userEmail}</p>
                  <h4 className="text-xl font-semibold mb-2">
                    {registration.storeName}
                  </h4>
                  <p className="mb-4">{registration.description}</p>
                  {/* Display other registration fields as needed */}

                  <p>PROOF IMAGES:</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {registration.imageUrls &&
                      registration.imageUrls.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Img ${index}`}
                          className="w-20 h-20 object-cover rounded-md shadow-md"
                          onClick={() => handleImageClick(imageUrl)}
                        />
                      ))}
                  </div>
                  <div className="flex justify-between">
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
                      onClick={() => handleApproval(registration.id)}
                    >
                      Approve
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
                      onClick={() => handleDeclined(registration.id)}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {registrationData.filter(
            (registration) =>
              registration.status === "approved" &&
              registration.municipality === adminPlace // Filter by municipality
          ).length === 0 && <p>No approved found.</p>}
        </div>
      )}

      {displayOption === "declined" && (
        <div className="font-semibold mt-4">
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {registrationData
              .filter(
                (registration) =>
                  registration.status === "declined" &&
                  registration.municipality === adminPlace // Filter by municipality
              )
              .map((registration) => (
                <div
                  key={registration.id}
                  className="bg-white p-3 shadow-md rounded-md"
                >
                  <div className="flex justify-between">
                    <p>FROM:</p>
                    <DeleteIcon
                      onClick={() => handleDelete(registration.id)}
                      className="text-red-500 hover:text-red-600 cursor-pointer"
                    />
                  </div>
                  <p className="mb-4 font-bold">{registration.userEmail}</p>
                  <h4 className="text-xl font-semibold mb-2">
                    {registration.storeName}
                  </h4>
                  <p className="mb-4">{registration.description}</p>
                  {/* Display other registration fields as needed */}

                  <p>PROOF IMAGES:</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {registration.imageUrls &&
                      registration.imageUrls.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Img ${index}`}
                          className="w-20 h-20 object-cover rounded-md shadow-md"
                          onClick={() => handleImageClick(imageUrl)}
                        />
                      ))}
                  </div>

                  <div className="flex justify-between">
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
                      onClick={() => handleApproval(registration.id)}
                    >
                      Approve
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
                      onClick={() => handleDeclined(registration.id)}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {registrationData.filter(
            (registration) =>
              registration.status === "declined" &&
              registration.municipality === adminPlace // Filter by municipality
          ).length === 0 && <p>No declined found.</p>}
        </div>
      )}

      {displayOption === "forApproval" && (
        <div className="font-semibold mt-4">
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {registrationData
              .filter(
                (registration) =>
                  registration.status === "pending" &&
                  registration.municipality === adminPlace // Filter by municipality
              )
              .map((registration) => (
                <div
                  key={registration.id}
                  className="bg-white p-3 shadow-md rounded-md"
                >
                  <div className="flex justify-between">
                    <p>FROM:</p>
                    <DeleteIcon
                      onClick={() => handleDelete(registration.id)}
                      className="text-red-500 hover:text-red-600 cursor-pointer"
                    />
                  </div>
                  <p className="mb-4 font-bold">{registration.userEmail}</p>
                  <h4 className="text-xl font-semibold mb-2">
                    {registration.storeName}
                  </h4>
                  <p className="mb-4">{registration.description}</p>
                  {/* Display other registration fields as needed */}

                  <p>PROOF IMAGES:</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {registration.imageUrls &&
                      registration.imageUrls.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Img ${index}`}
                          className="w-20 h-20 object-cover rounded-md shadow-md"
                          onClick={() => handleImageClick(imageUrl)}
                        />
                      ))}
                  </div>

                  <div className="flex justify-between">
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
                      onClick={() => handleApproval(registration.id)}
                    >
                      Approve
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
                      onClick={() => handleDeclined(registration.id)}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {registrationData.filter(
            (registration) =>
              registration.status === "pending" &&
              registration.municipality === adminPlace // Filter by municipality
          ).length === 0 && <p>No pending registrations found.</p>}
        </div>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this registration?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleConfirmationDialogClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {selectedImage && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-90">
          <div className="max-w-3/4 max-h-3/4">
            <img
              src={selectedImage}
              alt="Fullscreen"
              className="w-full h-auto max-h-full object-contain"
            />
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Approval;
