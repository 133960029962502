// src/chatbotTheme.js
const chatbotTheme = {
    background: '#D3D3D3',
    headerBgColor: '#1589FF',
    headerFontSize: '20px',
    botBubbleColor: '#1589FF',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: '#1589FF',
    userFontColor: 'white',
  };
  
  export default chatbotTheme;
  