import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TBackgroup from "../images/jeepss.jpg";
import Box from "@mui/material/Box";
import Gallery from "react-image-gallery";
import "../CSS/Gallery.css";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import firebaseConfig from "../data/firebaseConfig";
import useAuth from "../ProtectedRoute/useAuth";
import { getStorage, ref, deleteObject } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

//Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Terminal Page
const Terminal = () => {
  const [selectedCallout, setSelectedCallout] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedPlaceToRemove, setSelectedPlaceToRemove] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [PlacesData, setPlacesData] = useState([]);
  const { isAdmin, adminPlace } = useAuth();

  useEffect(() => {
    // Add a Firestore listener to fetch and update places whenever there's a change
    const unsubscribe = onSnapshot(collection(db, "puvsPlaces"), (snapshot) => {
      const placeData = [];
      snapshot.forEach((doc) => {
        placeData.push({ id: doc.id, ...doc.data() });
      });
      setPlacesData(placeData);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [selectedCallout]);

  const handleEditPlace = (place) => {
    // Navigate to the Admin component with the selected page value as a query parameter
    navigate("/admin", { state: { selectedPage: "Public Transit" } });
    navigator.clipboard
      .writeText(place.name)
      .then(() => {
        console.log("Place name copied to clipboard:", place.name);
        // Notify the user or perform any other actions upon successful copy
      })
      .catch((err) => {
        console.error("Error copying place name:", err);
        // Handle errors if copying fails
      });
  };

  const handleRemovePlace = (place) => {
    setSelectedPlaceToRemove(place);
    setShowConfirmation(true);
  };

  const handleConfirmRemove = async () => {
    try {
      if (selectedPlaceToRemove) {
        const placeRef = doc(db, "puvsPlaces", selectedPlaceToRemove.id);
        await deleteDoc(placeRef);

        if (selectedPlaceToRemove.imageName) {
          const storage = getStorage(app);
          const imageRef = ref(
            storage,
            `puvsPlacesImages/${selectedPlaceToRemove.imageName}`
          );
          await deleteObject(imageRef);
          console.log(
            `Removed place: ${selectedPlaceToRemove.name} and image: ${selectedPlaceToRemove.imageName}`
          );
        } else {
          console.warn(
            `place ${selectedPlaceToRemove.name} does not have an associated image.`
          );
        }
        setSelectedPlaceToRemove(null);
      }
    } catch (error) {
      console.error("Error removing place and image:", error);
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to calculate distance between two sets of coordinates
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const handleNearbyClick = async () => {
    try {
      // Check if Geolocation is supported by the browser
      if ("geolocation" in navigator) {
        // Use the Geolocation API to get the user's location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            const filteredPlaces = PlacesData.map((place) => {
              // Calculate distance and add it to each place
              const distance = calculateDistance(
                userLocation.latitude,
                userLocation.longitude,
                place.coordinates[1],
                place.coordinates[0]
              );

              console.log(`Distance to ${place.name}: ${distance} km`);
              place.distance = distance; // Add the distance property

              return place;
            })
              .filter((place) => place.distance <= 10)
              .sort((a, b) => a.distance - b.distance); // Sort by distance

            console.log("Sorted and Filtered Places:", filteredPlaces);

            // Set the filtered nearby places
            setPlacesData(filteredPlaces);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by your browser.");
      }
    } catch (error) {
      console.error("Error fetching nearby places:", error);
    }
  };

  return (
    <div
      className="bg-white justify-content-center align-items-center min-h-screen"
      style={{
        backgroundImage: `url(${TBackgroup})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        zIndex: -1,
      }}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-12 mb-12">
        <div className="mx-auto max-w-2xl py-3 sm:py-16 lg:max-w-none lg:py-10">
          <h2
            className="text-2xl font-bold text-black-500 text-center"
            style={{ fontFamily: "Montserrat, sans-serif" }}
          >
            Public Utility Vehicle Terminals
          </h2>
          <Button onClick={handleNearbyClick}>Nearby Me</Button>
          {/* Add a dropdown to select a city */}
          <div className="mb-4">
            <label htmlFor="citySelect" className="text-gray-600">
              Select a Location:
            </label>
            <select
              id="citySelect"
              className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-300"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              <option value="">All Cities</option>
              {/* Generate options based on unique cities in TerminalData */}
              {isAdmin ? ( // Check if the user is an admin
                <option value={adminPlace}>{adminPlace}</option>
              ) : (
                // If not an admin, show all available cities
                Array.from(new Set(PlacesData.map((place) => place.city))).map(
                  (city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  )
                )
              )}
            </select>
          </div>

          {/* Confirmation Modal */}
          {showConfirmation && (
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="bg-white rounded-lg w-96 mx-auto overflow-hidden shadow-xl z-10">
                  <div className="p-6">
                    <p className="mb-4">
                      Are you sure you want to remove this terminal?
                    </p>
                    <div className="flex justify-end">
                      <Button
                        onClick={() => setShowConfirmation(false)}
                        variant="outlined"
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                      <span className="inline-block w-3" />
                      <Button
                        onClick={handleConfirmRemove}
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        color="error"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mt-4 space-y-8 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {PlacesData.length === 0 && (
              <div className="text-center text-black-600 h-screen">
                No nearby places found.
              </div>
            )}

            {PlacesData.filter((callout) => {
              if (isAdmin) {
                return !isAdmin || callout.city === adminPlace;
              } else {
                return !selectedCity || callout.city === selectedCity;
              }
            }).map((callout) => (
              <div
                key={callout.name}
                className="group relative p-3"
                onClick={() => setSelectedCallout(callout)} // Open modal when clicked
              >
                <div className="relative h-64 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-52">
                  <img
                    src={callout.imageSrc[0]}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                  <div
                    className="absolute bottom-0 left-0 right-0 text-white text-center p-2"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                  >
                    <p className="text-sm font-medium">{callout.name}</p>
                    {callout.distance && (
                      <p className="text-xs text-gray-300">
                        Distance: {callout.distance.toFixed(2)} km
                      </p>
                    )}
                    {isAdmin && (
                      <div className="flex justify-between absolute bottom-0 left-0 right-0 p-2 text-white">
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation
                            handleRemovePlace(callout);
                          }}
                          className="text-red-500 hover:text-red-600 cursor-pointer mr-2"
                        />
                        <EditIcon
                          onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation
                            // Add your logic to handle editing here
                            handleEditPlace(callout);
                          }}
                          className="text-blue-500 hover:text-blue-600 cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Modal */}
          {selectedCallout !== null && (
            <Transition.Root
              show={selectedCallout !== null}
              as={React.Fragment}
            >
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={() => setSelectedCallout(null)} // Close modal when clicked
              >
                <div className="flex items-center justify-center min-h-screen">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

                  <div className="bg-white rounded-lg w-96 mx-auto overflow-hidden shadow-xl z-10">
                    <div className="relative">
                      <Gallery
                        items={selectedCallout.imageSrc.map((src, index) => ({
                          original: src,
                          thumbnail: src, // You can use the same image as a thumbnail
                        }))}
                      />
                      <button
                        type="button"
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                        onClick={() => setSelectedCallout(null)} // Close modal when clicked
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="p-6">
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Details" {...a11yProps(0)} />
                            <Tab label="Schedule" {...a11yProps(1)} />
                            <Tab label="Fare" {...a11yProps(2)} />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          <h3 className="text-xl font-semibold mb-2">
                            {selectedCallout.name}
                          </h3>
                          <p className="text-gray-600 mb-4 text-justify">
                            {selectedCallout.description}
                          </p>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <p>
                            First Trip Time:{" "}
                            {selectedCallout.availability.startTime.hours}:
                            {selectedCallout.availability.startTime.minutes}{" "}
                            {selectedCallout.availability.startTime.period}{" "}
                            <br></br>
                            Last Trip Time:{" "}
                            {selectedCallout.availability.endTime.hours}:
                            {selectedCallout.availability.endTime.minutes}{" "}
                            {selectedCallout.availability.endTime.period}
                          </p>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                          <p className="text-justify">{selectedCallout.fare}</p>
                        </CustomTabPanel>
                      </Box>
                      <Link
                        to={{
                          pathname: "/Navigation",
                          search: `?coordinates=${selectedCallout.coordinates}`, // Pass the coordinates as a query parameter
                        }}
                      >
                        <Button variant="contained" className="w-full">
                          Get Directions
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          )}
        </div>
      </div>
    </div>
  );
};

export default Terminal;
