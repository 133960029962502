import React from "react";
import MapBoxMap from "../components/MapboxMap";

function Homepage() {
  return (
    <div className="Homepage">
      <MapBoxMap/>
    </div>
  );
}

export default  Homepage;
