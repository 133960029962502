import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function useAuth() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminPlace, setAdminPlace] = useState(null); // Define adminPlace state

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setIsAdmin(userData.isAdmin || false);
          setAdminPlace(userData.adminPlace || null); // Fetch adminPlace from user data
        } else {
          setIsAdmin(false);
          setAdminPlace(null);
        }
      } else {
        setIsAdmin(false);
        setAdminPlace(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db]);

  return { user, isAdmin, adminPlace }; // Return adminPlace along with user and isAdmin
}

export default useAuth;
