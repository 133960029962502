import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AdminTouristSpot from "./AdminTouristSpot";
import AdminAccomodation from "./AdminAccomodation";
import AdminDelicacies from "./AdminDelicacies";
import AdminFestivities from "./AdminFestivities";
import AdminPublicTransit from "./AdminPublicTransit";

const Admin = () => {
  const location = useLocation();
  const initialPage = location.state ? location.state.selectedPage || "" : "";
  const [selectedPage, setSelectedPage] = useState(initialPage);

  useEffect(() => {
    localStorage.setItem("selectedPage", selectedPage);
  }, [selectedPage]);

  const handlePageSelect = (event) => {
    const selected = event.target.value;
    setSelectedPage(selected);
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-8 mb-8 mt-3">
      <div className="mb-4">
        <label htmlFor="citySelect" className="text-gray-600">
          Select a Page:
        </label>
        <select
          id="pageSelect"
          className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-300"
          onChange={handlePageSelect}
          value={selectedPage}
        >
          {selectedPage === "" && <option value="">Select Page</option>}
          <option value="Tourist Spot">Tourist Spot</option>
          <option value="Delicacies">Delicacies</option>
          <option value="Accommodation">Accommodation</option>
          <option value="Public Transit">Public Transit</option>
          <option value="Festivities">Festivities</option>
        </select>
      </div>

      {selectedPage === "Tourist Spot" && (
        <div>
          <AdminTouristSpot/>
        </div>
      )}

      {selectedPage === "Delicacies" && (
        <div>
          <AdminDelicacies/>
        </div>
      )}

      {selectedPage === "Accommodation" && (
        <div>
          <AdminAccomodation/>
        </div>
      )}

      {selectedPage === "Festivities" && (
        <div>
          <AdminFestivities/>
        </div>
      )}

      {selectedPage === "Public Transit" && (
        <div>
          <AdminPublicTransit/>
        </div>
      )}

    </div>
  );
};
export default Admin;
