import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Rating } from "@mui/material";
import DiscussionFestivities from "../components/DiscussionFestivities";
import EBackgroup from "../images/NNN.jpg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Gallery from "react-image-gallery";
import "../CSS/Gallery.css";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  query,
} from "firebase/firestore";
import firebaseConfig from "../data/firebaseConfig";
import useAuth from "../ProtectedRoute/useAuth";
import { getStorage, ref, deleteObject } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Festivities = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [showDiscussionModal, setShowDiscussionModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedPlaceToRemove, setSelectedPlaceToRemove] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [filter, setFilter] = useState("All Events");
  const [events, setEvents] = useState([]);
  const { isAdmin, user, adminPlace } = useAuth();
  const [averageRating, setAverageRating] = useState(0);
  // eslint-disable-next-line
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    // Add a Firestore listener to fetch and update places whenever there's a change
    const unsubscribe = onSnapshot(collection(db, "events"), (snapshot) => {
      const placeData = [];
      snapshot.forEach((doc) => {
        placeData.push({ id: doc.id, ...doc.data() });
      });
      setEvents(placeData);
    });

    // Fetch comments and ratings for the selected place
    if (selectedPlace) {
      const commentsQuery = query(
        collection(db, "events", selectedPlace.id, "comments")
      );

      onSnapshot(commentsQuery, (snapshot) => {
        const ratingsData = [];
        snapshot.forEach((doc) => {
          const commentData = doc.data();
          ratingsData.push(commentData.rating);
        });

        // Calculate the average rating
        const totalRating = ratingsData.reduce(
          (acc, rating) => acc + rating,
          0
        );
        const average =
          ratingsData.length === 0 ? 0 : totalRating / ratingsData.length;

        setRatings(ratingsData);
        setAverageRating(average);
      });
    }

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [selectedPlace]);

  const handleEditEvent = (place) => {
    // Navigate to the Admin component with the selected page value as a query parameter
    navigate("/admin", { state: { selectedPage: "Festivities" } });
    navigator.clipboard
      .writeText(place.name)
      .then(() => {
        console.log("Place name copied to clipboard:", place.name);
        // Notify the user or perform any other actions upon successful copy
      })
      .catch((err) => {
        console.error("Error copying place name:", err);
        // Handle errors if copying fails
      });
  };

  const handleRemoveEvent = (event) => {
    setSelectedPlaceToRemove(event);
    setShowConfirmation(true);
  };

  const handleConfirmRemove = async () => {
    try {
      if (selectedPlaceToRemove) {
        const placeRef = doc(db, "events", selectedPlaceToRemove.id);
        await deleteDoc(placeRef);

        if (selectedPlaceToRemove.imageName) {
          const storage = getStorage(app);
          const imageRef = ref(
            storage,
            `eventImages/${selectedPlaceToRemove.imageName}`
          );
          await deleteObject(imageRef);
          console.log(
            `Removed place: ${selectedPlaceToRemove.name} and image: ${selectedPlaceToRemove.imageName}`
          );
        } else {
          console.warn(
            `place ${selectedPlaceToRemove.name} does not have an associated image.`
          );
        }
        setSelectedPlaceToRemove(null);
      }
    } catch (error) {
      console.error("Error removing place and image:", error);
    } finally {
      setShowConfirmation(false);
    }
  };

  useEffect(() => {
    // Add a Firestore listener to fetch and update events whenever there's a change
    const unsubscribe = onSnapshot(collection(db, "events"), (snapshot) => {
      const eventData = [];
      snapshot.forEach((doc) => {
        eventData.push({ id: doc.id, ...doc.data() });
      });
      setEvents(eventData);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModal = (place) => {
    setSelectedPlace(place);
    setShowDiscussionModal(false);
  };

  const closeModal = () => {
    setSelectedPlace(null);
  };

  const openDiscussionModal = () => {
    setShowDiscussionModal(true);
  };

  const closeDiscussionModal = () => {
    setShowDiscussionModal(false);
  };

  const handleBackToPlaceContent = () => {
    closeDiscussionModal();
  };

  function formatAvailabilityDate(dateInfo) {
    if (dateInfo) {
      const { day, month, year, hour, minute, isAM } = dateInfo;
      const formattedDate = `${month}/${day}/${year}`;
      const formattedTime = `${hour}:${minute} ${isAM ? "AM" : "PM"}`;
      return `${formattedDate}, ${formattedTime}`;
    } else {
      return "Not available";
    }
  }

  const currentDate = new Date();

  // Filter events based on selected criteria
  const filteredEvents = events.filter((event) => {

    // Filter by adminPlace when the user is an admin
    if (isAdmin && adminPlace && event.city !== adminPlace) {
      return false;
    }

    // Filter by city when the user is not an admin
    if (!isAdmin && selectedCity && event.city !== selectedCity) {
      return false;
    }

    // Calculate the start and end date and time of the event
    const eventStartDate = new Date(
      event.start.year,
      event.start.month - 1,
      event.start.day,
      event.start.isAM ? event.start.hour : event.start.hour + 12,
      event.start.minute
    );

    const eventEndDate = new Date(
      event.end.year,
      event.end.month - 1,
      event.end.day,
      event.end.isAM ? event.end.hour : event.end.hour + 12,
      event.end.minute
    );

    if (filter === "current") {
      // Filter for current events
      return currentDate >= eventStartDate && currentDate <= eventEndDate;
    } else if (filter === "upcoming") {
      // Filter for upcoming events (considering 30 days before start date)
      const endDateThreshold = new Date(currentDate);
      endDateThreshold.setDate(currentDate.getDate() + 50);

      const isUpcoming =
        eventStartDate >= currentDate && eventStartDate <= endDateThreshold;

      // Ensure the current date is before the event's end date
      const isCurrent = currentDate <= eventEndDate;

      return isUpcoming && isCurrent;
    }

    return true; // "All Events"
  });

  const uniqueCities = Array.from(new Set(events.map((event) => event.city)));

  // Function to calculate distance between two sets of coordinates
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const handleNearbyClick = async () => {
    try {
      // Check if Geolocation is supported by the browser
      if ("geolocation" in navigator) {
        // Use the Geolocation API to get the user's location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            const filteredPlaces = events
              .map((place) => {
                // Calculate distance and add it to each place
                const distance = calculateDistance(
                  userLocation.latitude,
                  userLocation.longitude,
                  place.coordinates[1],
                  place.coordinates[0]
                );

                console.log(`Distance to ${place.name}: ${distance} km`);
                place.distance = distance; // Add the distance property

                return place;
              })
              .filter((place) => place.distance <= 10)
              .sort((a, b) => a.distance - b.distance); // Sort by distance

            console.log("Sorted and Filtered Places:", filteredPlaces);

            // Set the filtered nearby places
            setEvents(filteredPlaces);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by your browser.");
      }
    } catch (error) {
      console.error("Error fetching nearby places:", error);
    }
  };

  return (
    <div
      className="bg-white justify-content-center align-items-center min-h-screen"
      style={{
        backgroundImage: `url(${EBackgroup})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-12 mb-12">
        <div className="mx-auto max-w-2xl py-3 sm:py-16 lg:max-w-none lg:py-10">
          <h2
            className="text-2xl font-bold text-black-500 text-center"
            style={{ fontFamily: "Montserrat, sans-serif" }}
          >
            Festivities
          </h2>
          <Button onClick={handleNearbyClick}>Nearby Me</Button>
          <div className="mb-4 flex space-x-4">
            <div className="flex-1">
              <label htmlFor="citySelect" className="text-gray-600">
                Select a Location:
              </label>
              <select
                id="citySelect"
                className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-300"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <option value="">All Cities</option>
                {uniqueCities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-1">
              <label htmlFor="filterSelect" className="text-gray-600">
                Select Filter:
              </label>
              <select
                id="filterSelect"
                className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-300"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All Events</option>
                <option value="current">Current Events</option>
                <option value="upcoming">Upcoming Events</option>
              </select>
            </div>
          </div>

          {/* Confirmation Modal */}
          {showConfirmation && (
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="bg-white rounded-lg w-96 mx-auto overflow-hidden shadow-xl z-10">
                  <div className="p-6">
                    <p className="mb-4">
                      Are you sure you want to remove this event?
                    </p>
                    <div className="flex justify-end">
                      <Button
                        onClick={() => setShowConfirmation(false)}
                        variant="outlined"
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                      <span className="inline-block w-3" />
                      <Button
                        onClick={handleConfirmRemove}
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        color="error"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* display screen size */}
          {filteredEvents.length === 0 && (
            <div className="text-center h-screen">
              /
              {filter === "current" ? (
                <p>No events scheduled for today.</p>
              ) : (
                <p>No upcoming events.</p>
              )}
            </div>
          )}
          {filteredEvents.length > 0 && (
            <div className="mt-4 space-y-8 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {filteredEvents.map((event) => (
                <div
                  key={event.name} // Use event.name instead of events.name
                  className="group relative p-3"
                  onClick={() => openModal(event)} // Open modal when clicked
                >
                  <div className="relative h-64 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-52">
                    <img
                      src={event.imageSrc[0]} // Use event.imageSrc instead of events.imageSrc
                      alt={event.name} // Use event.name instead of events.name
                      className="h-full w-full object-cover object-center"
                    />
                    <div
                      className="absolute bottom-0 left-0 right-0 text-white text-center p-2"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                    >
                      <p className="text-sm font-medium">
                        {event.name}
                        <br></br>
                        {event.city}
                      </p>
                      {event.distance && (
                        <p className="text-xs text-gray-300">
                          Distance: {event.distance.toFixed(2)} km
                        </p>
                      )}
                      {isAdmin && (
                        <div className="flex justify-between absolute bottom-0 left-0 right-0 p-2 text-white">
                          <DeleteIcon
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event propagation
                              handleRemoveEvent(event);
                            }}
                            className="text-red-500 hover:text-red-600 cursor-pointer mr-2"
                          />
                          <EditIcon
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event propagation
                              // Add your logic to handle editing here
                              handleEditEvent(event);
                            }}
                            className="text-blue-500 hover:text-blue-600 cursor-pointer"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Modal */}
          {selectedPlace !== null && !showDiscussionModal && (
            <Transition.Root show={selectedPlace !== null} as={React.Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal} // Close modal when clicked
              >
                <div className="flex items-center justify-center min-h-screen">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  <div className="bg-white rounded-lg w-96 mx-auto overflow-hidden shadow-xl z-10">
                    <div className="relative">
                      <Gallery
                        items={selectedPlace.imageSrc.map((src) => ({
                          original: src,
                          thumbnail: src, // You can use the same image as a thumbnail
                        }))}
                      />
                      <button
                        type="button"
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                        onClick={closeModal} // Close modal when clicked
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="p-6">
                      <Rating
                        name="size-small"
                        value={averageRating}
                        precision={0.1}
                        size="small"
                        readOnly
                      />
                      <Link
                        onClick={() => openDiscussionModal()} // Set showDiscussion to true when the "review" link is clicked
                        style={{ marginLeft: "5px" }}
                      >
                        review
                      </Link>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Details" {...a11yProps(0)} />
                            <Tab label="Schedule" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          <h3 className="text-xl font-semibold mb-2">
                            {selectedPlace.name}
                          </h3>
                          <p className="text-gray-600 mb-4 text-justify">
                            {selectedPlace.description}
                          </p>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <p>
                            Available on:{" "}
                            {formatAvailabilityDate(selectedPlace.start)}
                            <br />
                            Until: {formatAvailabilityDate(selectedPlace.end)}
                          </p>
                        </CustomTabPanel>
                      </Box>
                      <Link
                        to={{
                          pathname: "/Navigation",
                          search: `?coordinates=${selectedPlace.coordinates[0]},${selectedPlace.coordinates[1]}`, // Pass the coordinates as a query parameter
                        }}
                      >
                        <Button variant="contained" className="w-full">
                          Get Directions
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          )}
          {/* Discussion Modal */}
          {showDiscussionModal && (
            <Transition.Root show={showDiscussionModal} as={React.Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeDiscussionModal}
              >
                <div className="flex items-center justify-center min-h-screen">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  <div className="bg-white rounded-lg w-96 mx-auto overflow-hidden shadow-xl z-10">
                    <div className="p-6">
                      <DiscussionFestivities
                        placeId={selectedPlace.id}
                        user={user}
                        onBack={handleBackToPlaceContent}
                      />
                      <button
                        onClick={closeDiscussionModal}
                        className="text-blue-500 mt-4 cursor-pointer"
                      >
                        Close Discussion
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          )}
        </div>
      </div>
    </div>
  );
};

export default Festivities;
