import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxAccessToken from "../data/mapboxAccessToken";
import "./StartingPage.css";
import ReCAPTCHA from "react-google-recaptcha";

const StartingPage = ({ onEnter }) => {
  const [canEnter, setCanEnter] = useState(false);
  const mapContainerRef = useRef(null);
  const btnSpinRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/xsend/clk9t88s8004g01rddzdsfclr",
      projection: "globe",
      zoom: 1.5,
      center: [-90, 40],
    });

    //spinning earth
    map.on("style.load", () => {
      map.setFog({});
    });

    const secondsPerRevolution = 120;
    const maxSpinZoom = 5;
    const slowSpinZoom = 3;
    let userInteracting = false;
    let spinEnabled = true;

    function spinGlobe() {
      const zoom = map.getZoom();
      if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
        let distancePerSecond = 360 / secondsPerRevolution;
        if (zoom > slowSpinZoom) {
          const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
          distancePerSecond *= zoomDif;
        }
        const center = map.getCenter();
        center.lng -= distancePerSecond;
        map.easeTo({ center, duration: 1000, easing: (n) => n });
      }
    }

    map.on("mousedown", () => {
      userInteracting = true;
    });

    map.on("mouseup", () => {
      userInteracting = false;
      spinGlobe();
    });

    map.on("dragend", () => {
      userInteracting = false;
      spinGlobe();
    });

    map.on("pitchend", () => {
      userInteracting = false;
      spinGlobe();
    });

    map.on("rotateend", () => {
      userInteracting = false;
      spinGlobe();
    });

    map.on("moveend", () => {
      spinGlobe();
    });
    spinGlobe();

    btnSpinRef.current.addEventListener("click", (e) => {
      navigateToDistrict4();
    });

    function navigateToDistrict4() {
      map.flyTo({
        center: [121.47092538364092, 14.238540776383556],
        zoom: 11,
        pitch: 50, // Set the desired pitch value (in degrees)
        essential: true,
      });
    }

    return () => {
      map.remove();
    };
  }, []);

  function onChange(value) {
    setCanEnter(true); // Enable the button when the ReCAPTCHA value is set
  }

  const handleEnter = () => {
    if (canEnter) {
      // Implement your logic when the button is clicked and the ReCAPTCHA is validated
      onEnter();
    } else {
      // Display a message or prevent action as ReCAPTCHA validation is pending
      console.log("Please complete the ReCAPTCHA.");
    }
  };

  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div
        id="map"
        ref={mapContainerRef}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <div style={{ zIndex: 1 }}>
        <button
          id="btn-spin"
          ref={btnSpinRef}
          onClick={handleEnter} // Check ReCAPTCHA validation on button click
          disabled={!canEnter} // Disable button if ReCAPTCHA is not validated
        >
          EXPLORE TO DISTRICT 4 OF LAGUNA!
        </button>
      </div>
      <div style={{ position: "absolute", bottom: 20, right: 10, zIndex: 1 }}>
        <ReCAPTCHA
          style={{ display: "inline-block" }}
          sitekey="6LdJUSopAAAAAF4NieMyVUhTSI6BrgfBF2tYZfmT"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default StartingPage;
