import React from "react";
import { Button } from "@mui/material";
import ItinenaryMap from "./ItinenaryMap";

function ItinerarySummary({ itinerary, onRemovePlace }) {
  return (
    <div className="mt-4">
      <h2 className="text-xl font-semibold mb-2">Itinerary Summary</h2>
      <ul className="list-disc pl-6">
        {itinerary.map((place) => (
          <li key={place.name}>
            <h3 className="text-lg font-medium">{place.name}</h3>
            <p className="text-gray-600">{place.description}</p>
            <Button
              variant="outlined"
              color="error"
              style={{ margin: "10px" }}
              onClick={() => onRemovePlace(place)}
            >
              Remove
            </Button>
          </li>
        ))}
        <div
          className="map-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: "50",
            flex: 1,
            marginBottom: "20px", // Add a bottom margin of 20px
          }}
        >
          <ItinenaryMap itinerary={itinerary} />
        </div>
      </ul>
    </div>
  );
}

export default ItinerarySummary;
