import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../data/firebaseConfig";
import CircularProgress from "@mui/material/CircularProgress";

export default function UserData() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      // Handle unauthenticated users or redirect to the login page
      navigate("/login");
      return;
    }

    const userFormDocRef = doc(db, "users", user.uid, "userData", "document");

    const fetchData = async () => {
      try {
        const docSnapshot = await getDoc(userFormDocRef);
        if (docSnapshot.exists()) {
          // User data found, set it to the state
          setUserData(docSnapshot.data());
        } else {
          // Handle the case where user data doesn't exist
          console.log("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        // Update loading state to false when data retrieval is complete
        setLoading(false);
      }
    };

    fetchData();
  }, [auth, db, navigate]);

  const handleEdit = () => {
    navigate("/userform"); // Redirect the user to the /userform route
  };

  if (loading) {
    // Show loading indicator while fetching data
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <p>Loading user data...</p>
      </div>
    );
  }

  return (
    <div
      style={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "40px",
        marginBottom: "70px",
      }}
    >
      <form className="pb-5" >
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            My Tourist Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Personal details
          </p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {userData && (
              <>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Full name
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`${userData.firstName} ${userData.lastName}`}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Tourista Nickname
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {userData.username}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Address
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`${userData.streetAddress}, ${userData.city}, ${userData.region}`}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Gender
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {userData.gender}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    About
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {userData.about}
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>
        <div className="mt-6 flex items-center justify-center gap-x-6">
          <button
            onClick={handleEdit}
            type="button"
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
          >
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}
