import React, { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Button } from "@mui/material";
import ItinerarySummary from "./ItinerarySummary";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import firebaseConfig from "../data/firebaseConfig";

const app = initializeApp(firebaseConfig);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LocationSelector() {
  const [selected, setSelected] = useState(null);
  const [itinerary, setItinerary] = useState(() => {
    // Retrieve itinerary from localStorage on component mount
    const storedItinerary = localStorage.getItem("itinerary");
    return storedItinerary ? JSON.parse(storedItinerary) : [];
  });
  const [showSummary, setShowSummary] = useState(false); // To toggle itinerary visibility
  const [locationData, setLocationData] = useState([]);

  // Function to save itinerary to localStorage
  const saveItineraryToLocalStorage = (updatedItinerary) => {
    localStorage.setItem("itinerary", JSON.stringify(updatedItinerary));
  };

  // Update localStorage whenever itinerary changes
  useEffect(() => {
    saveItineraryToLocalStorage(itinerary);
  }, [itinerary]);

  useEffect(() => {
    const db = getDatabase(app);
    const locationsRef = ref(db, "/locations");

    onValue(
      locationsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setLocationData(data); // Set the array of locations directly
          if (Array.isArray(data) && data.length > 0) {
            setSelected(data[0]);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );

    // Clean up the Firebase listener when component unmounts
    return () => {
      // Detach the listener here if necessary
    };
  }, []);

  const handleItineraryToggle = () => {
    setShowSummary(!showSummary);
  };

  const addToItinerary = (place) => {
    setItinerary([...itinerary, place]);
  };

  const removeFromItinerary = (placeToRemove) => {
    const updatedItinerary = itinerary.filter(
      (place) => place !== placeToRemove
    );
    setItinerary(updatedItinerary);
  };

  const isPlaceAdded = (place) => {
    return itinerary.some((item) => item.name === place.name);
  };

  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              Select Location
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <img
                    src={selected?.imageSrc}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate">{selected?.name}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {locationData.map((location, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={location}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <img
                              src={location.imageSrc}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {location.name}
                            </span>
                          </div>

                          {selected && (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Available Places</h2>
        {selected && (
          <ul className="list-disc pl-6">
            {selected.places &&
              selected.places.map((place, index) => (
                <li key={index}>
                  <h3 className="text-lg font-medium">{place.name}</h3>
                  <p className="text-gray-600 text-justify">
                    {place.description}
                  </p>
                  <img
                    src={place.imageSrc}
                    alt={`View of ${place.name}`}
                    className="mt-2 h-24 w-32 object-cover rounded-md"
                  />
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px",
                      backgroundColor: isPlaceAdded(place) ? "green" : undefined,
                      color: isPlaceAdded(place) ? "white" : undefined,
                    }}
                    onClick={() => {
                      if (!isPlaceAdded(place)) {
                        addToItinerary(place);
                      } else {
                        removeFromItinerary(place);
                      }
                    }}
                  >
                    {isPlaceAdded(place)
                      ? "Added Itenerary"
                      : "Add to itinerary"}
                  </Button>
                </li>
              ))}
          </ul>
        )}

        <Button
          variant="contained"
          onClick={handleItineraryToggle}
          className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-lg focus:outline-none"
        >
          {showSummary ? "Hide Added Itinerary" : "Show Added Itinerary"}
        </Button>

        {showSummary && itinerary.length > 0 ? (
          <ItinerarySummary
            itinerary={itinerary}
            onRemovePlace={removeFromItinerary}
          />
        ) : (
          showSummary && (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              No added places in the itinerary.
            </p>
          )
        )}
      </div>
    </div>
  );
}
