import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./pages/Navigation";
import Festivities from "./pages/Festivities";
import Delicacies from "./pages/Delicacies";
import LoginPage from "./pages/LoginPage";
import Accomodation from "./pages/Accomodation";
import Terminal from "./pages/Terminal";
import Homepage from "./pages/Homepage";
import StartingPage from "./pages/StartingPage";
import Places from "./pages/Places";
import CreateAccount from "./components/CreateAccount";
import Topnavbar from "./navigations/Topnavbar";
import ForgotPassword from "./components/ForgotPassword";
import ProtectedUserForm from "./ProtectedRoute/ProtectedUserForm";
import ProtectedAdminPage from "./ProtectedRoute/ProtectedAdminPage";
import ProtectedRegistration from "./ProtectedRoute/ProtectedRegistration";
import ProtectedApprovalPage from "./ProtectedRoute/ProtectedApprovalPage";
import UserData from "./components/UserData";
import ProtectedAccommodationForm from "./ProtectedRoute/ProtectedAccommodationForm";
import ProtectedDelicaciesForm from "./ProtectedRoute/ProtectedDelicaciesForm";

function NavigationRoute() {
  const [showMainContent, setShowMainContent] = useState(false);

  useEffect(() => {
    const isUserEntered = localStorage.getItem("userEntered");
    if (isUserEntered) {
      setShowMainContent(true);
    }

    let timeout;

    function resetTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        localStorage.removeItem("userEntered");
        setShowMainContent(false);
      }, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    }

    function handleTouch() {
      resetTimer();
    }

    // Event listeners for touch events
    window.addEventListener("touchstart", handleTouch);
    window.addEventListener("touchmove", handleTouch);

    // Initial timer setup
    resetTimer();

    // Cleanup: remove event listeners
    return () => {
      window.removeEventListener("touchstart", handleTouch);
      window.removeEventListener("touchmove", handleTouch);
      clearTimeout(timeout);
    };
  }, []);

  const handleEnterButton = () => {
    // Store in localStorage to remember that the user has entered
    localStorage.setItem("userEntered", "true");

    // Delay the update of showMainContent by a specific duration (in milliseconds)
    const delayDuration = 5000; // Adjust this value as needed
    setTimeout(() => {
      setShowMainContent(true);
    }, delayDuration);
  };

  return (
    <BrowserRouter>
      <Routes>
        {showMainContent && ( // Only render NavBars and other routes if showMainContent is true
          <Route path="/" element={<Topnavbar />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/navigation" element={<Navigation />} />
            <Route path="/places" element={<Places />} />
            <Route path="/festivities" element={<Festivities />} />
            <Route path="/delicacies" element={<Delicacies />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={<ProtectedAdminPage />} />
            <Route path="/accomodation" element={<Accomodation />} />
            <Route path="/terminal" element={<Terminal />} />
            <Route path="/userform" element={<ProtectedUserForm />} />
            <Route path="/createaccount" element={<CreateAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/userdata" element={<UserData />} />
            <Route path="/registration" element={<ProtectedRegistration />} />
            <Route path="/approval" element={<ProtectedApprovalPage />} />
            <Route path="/accommodationForm" element={<ProtectedAccommodationForm />} />
            <Route path="/delicaciesForm" element={<ProtectedDelicaciesForm />} />
          </Route>
        )}
        <Route
          path="/" // This is the route for the starting page
          element={<StartingPage onEnter={handleEnterButton} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default NavigationRoute;
