import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import mapboxAccessToken from "../data/mapboxAccessToken";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import useAuth from "../ProtectedRoute/useAuth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import firebaseConfig from "../data/firebaseConfig";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const AdminPublicTransit = () => {
  const { adminPlace } = useAuth();
  const [placeData, setPlaceData] = useState({
    name: "",
    PhotoSphere: "",
    description: "",
    fare: "",
    imageSrc: [],
    imageAlt: "No chosen file",
    coordinates: ["", ""],
    availability: {
      startTime: {
        hours: "",
        minutes: "",
        period: "AM",
      },
      endTime: {
        hours: "",
        minutes: "",
        period: "AM",
      },
    },
    city: adminPlace || "",
  });

  const [imageUploading, setImageUploading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const markerRef = useRef(null);
  const mapRef = useRef(null);

  const municipalityCoordinates = {
    "Santa Cruz": [121.41466879855538, 14.281915080996798],
    Cavinti: [121.52708986296062, 14.24556685906704],
    Famy: [121.447645, 14.437787],
    Kalayaan: [121.479145, 14.32511],
    Luisiana: [121.510044, 14.185146],
    Lumban: [121.459361, 14.297124],
    Mabitac: [121.427904, 14.426752],
    Magdalena: [121.429401, 14.199537],
    Majayjay: [121.471614, 14.144784],
    Paete: [121.482694, 14.364237],
    Pagsanjan: [121.456073, 14.272613],
    Pakil: [121.478687, 14.38097],
    Pangil: [121.468441, 14.402897],
    Pila: [121.364792, 14.234284],
    "Santa Maria": [121.426853, 14.471469],
    Siniloan: [121.446098, 14.421212],
    // Add coordinates for other municipalities
  };

  const municipalityBounds = {
    "Santa Cruz": [
      [121.393089, 14.261971], // Southwest coordinates (lower longitude, lower latitude)
      [121.415749, 14.293329], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Cavinti: [
      [121.503296, 14.237097],
      [121.555138, 14.268875],
    ],
    Famy: [
      [121.434374, 14.425869],
      [121.477289, 14.453964],
    ],
    Kalayaan: [
      [121.475143, 14.321357], // Southwest coordinates (lower longitude, lower latitude)
      [121.483598, 14.330256], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Luisiana: [
      [121.50424, 14.179394], // Southwest coordinates (lower longitude, lower latitude)
      [121.521792, 14.191918], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Lumban: [
      [121.453686, 14.283764], // Southwest coordinates (lower longitude, lower latitude)
      [121.502867, 14.316451], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Mabitac: [
      [121.416178, 14.422253], // Southwest coordinates (lower longitude, lower latitude)
      [121.433988, 14.435678], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Magdalena: [
      [121.419954, 14.192292], // Southwest coordinates (lower longitude, lower latitude)
      [121.441841, 14.20806], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Majayjay: [
      [121.434116, 14.111938], // Southwest coordinates (lower longitude, lower latitude)
      [121.503983, 14.168201], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Paete: [
      [121.477032, 14.354163], // Southwest coordinates (lower longitude, lower latitude)
      [121.493554, 14.36755], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pagsanjan: [
      [121.436777, 14.259059], // Southwest coordinates (lower longitude, lower latitude)
      [121.470251, 14.28235], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pakil: [
      [121.467247, 14.374492], // Southwest coordinates (lower longitude, lower latitude)
      [121.492739, 14.388626], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pangil: [
      [121.454287, 14.391619], // Southwest coordinates (lower longitude, lower latitude)
      [121.482954, 14.413317], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pila: [
      [121.336012, 14.219043], // Southwest coordinates (lower longitude, lower latitude)
      [121.375923, 14.251905], // Northeast coordinates (higher longitude, higher latitude)
    ],
    "Santa Maria": [
      [121.390343, 14.449143], // Southwest coordinates (lower longitude, lower latitude)
      [121.440811, 14.488038], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Siniloan: [
      [121.440554, 14.408495], // Southwest coordinates (lower longitude, lower latitude)
      [121.458664, 14.424622], // Northeast coordinates (higher longitude, higher latitude)
    ],
    // Add bounding boxes for other municipalities
  };

  const handleTimeChange = (fieldName, value, timeType) => {
    setPlaceData((prevData) => {
      const updatedData = { ...prevData };
      const time = { ...updatedData.availability[timeType] };

      if (fieldName === "hours" && value >= 0 && value <= 12) {
        time.hours = value;
      } else if (fieldName === "minutes" && value >= 0 && value <= 59) {
        time.minutes = value;
      } else if (fieldName === "period" && (value === "AM" || value === "PM")) {
        time.period = value;
      }

      updatedData.availability[timeType] = time;
      return updatedData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    setPlaceData({
      ...placeData,
      [name]: uppercaseValue,
      city: adminPlace || "",
    });
  };

  const fetchPuvsPlacesData = async (spotName) => {
    try {
      const touristSpotsRef = collection(db, "puvsPlaces");
      const q = query(touristSpotsRef, where("name", "==", spotName));
      const querySnapshot = await getDocs(q);

      const marker = markerRef.current;
      const map = mapRef.current;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Update the form fields with the retrieved data
        setPlaceData({
          name: data.name || "",
          PhotoSphere: data.PhotoSphere || "",
          description: data.description || "",
          fare: data.fare || "",
          imageSrc: data.imageSrc || [],
          imageAlt: data.imageAlt || "No chosen file",
          coordinates: data.coordinates || ["", ""],
          availability: data.availability || {
            days: [],
            startTime: {
              hours: "",
              minutes: "",
              period: "AM",
            },
            endTime: {
              hours: "",
              minutes: "",
              period: "AM",
            },
          },
          city: data.city || "",
        }); // If coordinates are available, update the marker's position
        if (data.coordinates && marker && map) {
          const [lng, lat] = data.coordinates;
          // Update the marker's position
          marker.setLngLat([parseFloat(lng), parseFloat(lat)]);
          // Update the map's center to the marker's position
          map.setCenter([parseFloat(lng), parseFloat(lat)]);
        }
      });
    } catch (error) {
      console.error("Error fetching tourist spot data: ", error);
    }
  };

  const handleGetClick = async () => {
    // Fetch the data based on the search query when the Get button is clicked
    fetchPuvsPlacesData(searchQuery);
    try {
      const clipboardText = await navigator.clipboard.readText();
      setSearchQuery(clipboardText);
    } catch (error) {
      console.error("Error reading clipboard text:", error);
    }
  };

  const generateUniqueID = () => {
    const timestamp = new Date().getTime(); // Get timestamp in milliseconds
    const uniqueID = `image_${timestamp}`; // Create a unique ID using the timestamp
    return uniqueID;
  };

  const handleThumbnailUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uniqueID = generateUniqueID(); // Generate unique ID for the thumbnail image
        const storageRef = ref(
          storage,
          `puvsPlacesImages/${uniqueID}_${file.name}`
        );

        // Upload the thumbnail image to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL for the uploaded thumbnail image
        const thumbnailUrl = await getDownloadURL(storageRef);

        // Update placeData with the thumbnail image URL
        setPlaceData({
          ...placeData,
          imageSrc: [thumbnailUrl, ...placeData.imageSrc],
        });
      } catch (error) {
        console.error("Error uploading thumbnail image: ", error);
      }
    }
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      try {
        setImageUploading(true);

        const imageSrcArray = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const uniqueID = generateUniqueID(); // Generate unique ID for each image
          const storageRef = ref(
            storage,
            `puvsPlacesImages/${uniqueID}_${file.name}`
          );

          // Upload the image to Firebase Storage
          await uploadBytes(storageRef, file);

          // Get the download URL for the uploaded image
          const imageUrl = await getDownloadURL(storageRef);

          // Add the image URL to the array
          imageSrcArray.push(imageUrl);
        }

        // Update placeData with the image URLs
        setPlaceData((prevData) => ({
          ...prevData,
          imageSrc: [...prevData.imageSrc, ...imageSrcArray],
        }));
      } catch (error) {
        console.error("Error uploading image: ", error);
      } finally {
        setImageUploading(false);
      }
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImageSrc = [...placeData.imageSrc];
    updatedImageSrc.splice(index, 1);
    setPlaceData({
      ...placeData,
      imageSrc: updatedImageSrc,
      imageAlt: "No chosen file", // Update the imageAlt when an image is removed
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields are empty
    if (
      !placeData.name ||
      !placeData.fare ||
      placeData.imageSrc.length === 0 ||
      placeData.coordinates.some((coord) => coord === "")
      // !placeData.city
    ) {
      // Display an error message or alert
      alert("Please fill in all the required fields before submitting.");
      return; // Prevent form submission
    }

    try {
      // Create a reference to the "touristSpots" collection
      const spotsCollection = collection(db, "puvsPlaces");

      // Check if the document already exists with the same name
      const existingSpotQuery = query(
        spotsCollection,
        where("name", "==", placeData.name)
      );

      const existingSpotSnapshot = await getDocs(existingSpotQuery);

      if (existingSpotSnapshot.size > 0) {
        // Document already exists, update the existing data
        existingSpotSnapshot.forEach(async (doc) => {
          await setDoc(doc.ref, { ...placeData }); // Set the entire document with new data
          alert("Data updated successfully!");
        });
      } else {
        // Document doesn't exist, add new data
        const docRef = await addDoc(spotsCollection, placeData);
        alert("Data submitted successfully!");
        console.log("Document written with ID: ", docRef.id);
      }

      // If the data is successfully saved, reset the form fields
      setPlaceData({
        name: "",
        PhotoSphere: "",
        description: "",
        fare: "",
        imageSrc: [],
        imageAlt: "No chosen file",
        coordinates: ["", ""],
        availability: {
          startTime: {
            hours: "",
            minutes: "",
            period: "AM",
          },
          endTime: {
            hours: "",
            minutes: "",
            period: "AM",
          },
        },
        city: "",
      });
    } catch (error) {
      console.error("Error adding/updating document: ", error);
    }
  };

  const initializeMap = () => {
    mapboxgl.accessToken = mapboxAccessToken;

    const bounds = [
      [116.928977, 4.587203],
      [126.611043, 21.321048],
    ];

    const coordinates = document.getElementById("coordinates");
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/xsend/clk9t69hj001501q98ucibq70",
      center: [121.41804992973309, 14.268459088733751],
      zoom: 15,
      maxBounds: bounds,
      pitch: 50,
    });

    const marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([121.41804992973309, 14.268459088733751])
      .addTo(map);

    const onDragEnd = () => {
      const lngLat = marker.getLngLat();
      coordinates.style.display = "block";
      coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;

      // Update placeData with the new coordinates without affecting other fields
      setPlaceData((prevData) => ({
        ...prevData,
        coordinates: [lngLat.lng.toString(), lngLat.lat.toString()],
      }));
    };

    marker.on("dragend", onDragEnd);

    // Assign references to marker and map to the refs
    markerRef.current = marker;
    mapRef.current = map;

    return map;
  };

  useEffect(() => {
    const map = initializeMap();

    return () => map.remove();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const map = initializeMap();

    if (adminPlace && mapRef.current) {
      const selectedBounds = municipalityBounds[adminPlace];

      if (selectedBounds) {
        const zoom = 15; // Set your desired zoom level
        mapRef.current.setZoom(zoom);
        mapRef.current.setMaxBounds(selectedBounds);

        const markerCoordinates = municipalityCoordinates[adminPlace];
        mapRef.current.setCenter(markerCoordinates); // Set the center to marker's position
        markerRef.current.setLngLat(markerCoordinates); // Set the marker's position

        // Optional: Set pitch after a delay
        setTimeout(() => {
          const pitchValue = 50; // Adjust pitch as needed
          mapRef.current.setPitch(pitchValue);
        }, 2000); // Adjust the delay as needed
      }
    }

    return () => map.remove();
    // eslint-disable-next-line
  }, [adminPlace]);

  return (
    <div className="container mx-auto px-4 py-4">
      <div className="flex justify-between mb-4">
        <h2 className="text-2xl font-semibold">
          Input Public Transit Places Data
        </h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Enter Terminal Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 mr-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
          <button
            onClick={handleGetClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          >
            Edit
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Municipality:
          </label>
          <select
            name="city"
            value={placeData.city}
            onChange={(e) => {
              setPlaceData({
                ...placeData,
                city: e.target.value,
              });
            }}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          >
            <option value={adminPlace}>{adminPlace}</option>
            {/* Add other municipality options */}
          </select>
        </div>
        <div style={{ position: "relative", height: "400px", width: "100%" }}>
          <div
            id="map"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              width: "100%",
              height: "100%", // Adjust the height of the map
            }}
          />
          <pre
            id="coordinates"
            className="coordinates"
            style={{
              display: "none",
              background: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              position: "absolute",
              bottom: "40px",
              left: "10px",
              padding: "5px 10px",
              margin: 0,
              fontSize: "11px",
              lineHeight: "18px",
              borderRadius: "3px",
            }}
          />
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Coordinates (Longitude):
            </label>
            <input
              type="text"
              name="coordinates"
              readOnly
              value={placeData.coordinates[0]} // Longitude
              onChange={(e) =>
                setPlaceData({
                  ...placeData,
                  coordinates: [e.target.value, placeData.coordinates[1]],
                })
              }
              className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Coordinates (Latitude):
            </label>
            <input
              type="text"
              name="coordinates"
              readOnly
              value={placeData.coordinates[1]} // Latitude
              onChange={(e) =>
                setPlaceData({
                  ...placeData,
                  coordinates: [placeData.coordinates[0], e.target.value],
                })
              }
              className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Terminal Name:
          </label>
          <input
            type="text"
            name="name"
            value={placeData.name}
            onChange={handleInputChange}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Description:
          </label>
          <input
            type="text"
            name="description"
            value={placeData.description}
            onChange={handleInputChange}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            360 image src:
          </label>
          <input
            type="text"
            name="PhotoSphere"
            placeholder="optional"
            value={placeData.PhotoSphere}
            onChange={handleInputChange}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Fare:
          </label>
          <input
            type="text"
            name="fare"
            value={placeData.fare}
            onChange={handleInputChange}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="text-gray-600">Thumbnail Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleThumbnailUpload}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Image Source:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            multiple // Allow multiple file selection
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
          {imageUploading && <p>Loading...</p>}
          {placeData.imageSrc.length > 0 && (
            <div className="mt-2">
              {placeData.imageSrc.map((imageUrl, index) => (
                <div key={index} className="flex items-center gap-2">
                  <img
                    src={imageUrl}
                    alt="Tourist Spot"
                    style={{ maxWidth: "100px", marginRight: "10px" }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(index)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            First Trip Time:
          </label>
          <div className="flex items-center">
            <input
              type="number"
              name="startTimeHours"
              value={placeData.availability.startTime.hours}
              onChange={(e) =>
                handleTimeChange("hours", e.target.value, "startTime")
              }
              min="1"
              max="12"
              placeholder="HH"
              className="block w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
            <span className="mx-2">:</span>
            <input
              type="number"
              name="startTimeMinutes"
              value={placeData.availability.startTime.minutes}
              onChange={(e) =>
                handleTimeChange("minutes", e.target.value, "startTime")
              }
              min="0"
              max="59"
              placeholder="MM"
              className="block w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
            <select
              name="startTimePeriod"
              value={placeData.availability.startTime.period}
              onChange={(e) =>
                handleTimeChange("period", e.target.value, "startTime")
              }
              className="w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Last Trip Time:
          </label>
          <div className="flex items-center">
            <input
              type="number"
              name="endTimeHours"
              value={placeData.availability.endTime.hours}
              onChange={(e) =>
                handleTimeChange("hours", e.target.value, "endTime")
              }
              min="1"
              max="12"
              placeholder="HH"
              className="block w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
            <span className="mx-2">:</span>
            <input
              type="number"
              name="endTimeMinutes"
              value={placeData.availability.endTime.minutes}
              onChange={(e) =>
                handleTimeChange("minutes", e.target.value, "endTime")
              }
              min="0"
              max="59"
              placeholder="MM"
              className="block w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            />
            <select
              name="endTimePeriod"
              value={placeData.availability.endTime.period}
              onChange={(e) =>
                handleTimeChange("period", e.target.value, "endTime")
              }
              className="w-1/4 mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AdminPublicTransit;
