import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material"; // Import CircularProgress for loading indicator
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function withAuthAdmin(Component) {
  return function AuthenticatedComponent(props) {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true); // State for checking if admin status is loading
    const navigate = useNavigate();

    useEffect(() => {
      const auth = getAuth();
      const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
        setUser(user);

        if (user) {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);

          getDoc(userDocRef)
            .then((docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setIsAdmin(userData.isAdmin);
              } else {
                setIsAdmin(false);
              }
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error getting user document:", error);
              setIsAdmin(false);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });

      return () => {
        unsubscribeAuth();
      };
    }, []);

    useEffect(() => {
      if (user === null) {
        return;
      }

      if (isAdmin) {
        // You can redirect or take an action here if the user is an admin
        // For hiding details, you can either render an empty div or take another action
        // navigate("/admin");
        return;
      }
    }, [isAdmin, navigate, user]);

    if (loading) {
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <CircularProgress />
        </div>
      );
    }

    if (user === null) {
      // Render an empty div instead of showing the message and button
      return <div />;
    }

    if (!isAdmin) {
      // Render an empty div for non-admin users instead of displaying a message
      return <div />;
    }

    // Render the provided component for authenticated admins
    return <Component {...props} />;
  };
}

export default withAuthAdmin;


