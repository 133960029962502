import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import "./MapDesign.css";
import "./Navigation.css";
import mapboxAccessToken from "../data/mapboxAccessToken";

const Navigation = () => {
  const directionsRef = useRef(null);
  const geolocateControlRef = useRef(null);
  const destinationMarkerRef = useRef(null);
  const synth = window.speechSynthesis;

  const location = useLocation();
  const destinationCoordinates = new URLSearchParams(location.search).get(
    "coordinates"
  );

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    const bounds = [
      [116.928977, 4.587203], // Southwest coordinates (lower longitude, lower latitude)
      [126.611043, 21.321048], // Northeast coordinates (higher longitude, higher latitude)
    ];

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/xsend/clk9t69hj001501q98ucibq70",
      center: [121.41804992973309, 14.268459088733751],
      zoom: 11,
      pitch: 50,
      antialias: true,
      maxBounds: bounds, // Set the map's geographical boundaries.
    });

    map.on("style.load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    });

    // Initialize the user location control
    geolocateControlRef.current = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    //directions
    directionsRef.current = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      interactive: true,
      geocodingQuery: {
        countries: "PH", // Limit directions to the Philippines
        proximity: [121.4167, 14.1667], // Central point within Laguna
        bbox: [120.976, 14.013, 121.661, 14.667], // Limit directions to Laguna, Philippines
      },
    });

    map.addControl(directionsRef.current, "top-right");

    // Create a function to speak directions
    function speakDirections(directionsText) {
      const utterance = new SpeechSynthesisUtterance(directionsText);
      synth.speak(utterance);
    }

    // When you receive directions from Mapbox Directions, speak the directions
    if (directionsRef.current) {
      let lastSpokenInstruction = '';
    
      const handleRouteEvent = (e) => {
        if (!lastSpokenInstruction) {
          const directions = e.route[0].legs[0].steps; // Extract directions from Mapbox Directions
          if (directions.length > 0) {
            const firstDirection = directions[0].maneuver.instruction;
            speakDirections(firstDirection); // Speak the first instruction
            lastSpokenInstruction = firstDirection; // Mark the first instruction as spoken
          }
        } else {
          const directions = e.route[0].legs[0].steps;
          if (directions.length > 0) {
            const newFirstDirection = directions[0].maneuver.instruction;
            if (newFirstDirection !== lastSpokenInstruction) {
              speakDirections(newFirstDirection); // Speak the new instruction if it differs
              lastSpokenInstruction = newFirstDirection; // Update last spoken instruction
            }
          }
        }
      };
    
      directionsRef.current.on('route', handleRouteEvent);
    }

    let destinationCoordinatesParsed = null;

    // Set the destination if coordinates are provided
    if (destinationCoordinates) {
      destinationCoordinatesParsed = destinationCoordinates
        .split(",")
        .map(parseFloat);

      if (
        destinationCoordinatesParsed.length === 2 &&
        !isNaN(destinationCoordinatesParsed[0]) &&
        !isNaN(destinationCoordinatesParsed[1])
      ) {
        if (directionsRef.current) {
          directionsRef.current.setDestination(destinationCoordinatesParsed);

          // Create a destination marker at the specified coordinates
          destinationMarkerRef.current = new mapboxgl.Marker()
            .setLngLat(destinationCoordinatesParsed)
            .addTo(map);

          // Fly to the destination marker's coordinates
          map.flyTo({
            center: destinationCoordinatesParsed,
            zoom: 15, // You can adjust the zoom level as needed
            speed: 1.2, // Controls the speed of the fly animation
            curve: 1, // Controls the easing of the fly animation
          });
        }
      }
    }

    // Define variables to track the last known user location
    let lastUserLocation = null;

    // Function to handle geolocation updates
    function handleGeolocationUpdate(e) {
      const longitude = e.coords.longitude;
      const latitude = e.coords.latitude;
      const heading = e.coords.heading;

      // Check if the user's location has significantly changed
      if (
        !lastUserLocation ||
        distance(lastUserLocation, [longitude, latitude]) > 0.01
      ) {
        lastUserLocation = [longitude, latitude];

        // Set the starting point for directions to the user's location
        if (directionsRef.current) {
          directionsRef.current.setOrigin([longitude, latitude]);
        }

        // Check if the heading is accurate and not null (null means unavailable)
        const isHeadingAccurate =
          heading !== null && heading >= 0 && heading < 360;

        map.easeTo({
          center: [longitude, latitude],
          bearing: isHeadingAccurate ? heading : 0,
          pitch: 60,
          zoom: 18,
          duration: 2000,
        });
      }
    }

    // Event listener for geolocation updates
    geolocateControlRef.current.on("geolocate", handleGeolocationUpdate);

    // Function to calculate the distance between two coordinates
    function distance(coord1, coord2) {
      const [lon1, lat1] = coord1;
      const [lon2, lat2] = coord2;
      const earthRadius = 6371; // Earth's radius in kilometers
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadius * c;
      return distance;
    }

    //zoom
    map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

    //location
    map.addControl(geolocateControlRef.current, "bottom-left");

    // Add new feature here

    return () => {
      if (destinationMarkerRef.current) {
        destinationMarkerRef.current.remove(); // Remove the destination marker if it exists
      }
      map.removeControl(directionsRef.current);
      map.remove();
    };
  }, [synth, destinationCoordinates]);

  const mapContainerRef = {
    position: "relative",
    top: 0,
    bottom: 0,
    width: "100%",
  };

  return (
    <div className="Maps">
      <div className="Mappage-map">
        <div style={mapContainerRef} id="map" />
      </div>
    </div>
  );
};

export default Navigation;
