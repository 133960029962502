import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import mapboxAccessToken from "../data/mapboxAccessToken";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import useAuth from "../ProtectedRoute/useAuth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebaseConfig from "../data/firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function AdminFestivities() {
  const { adminPlace } = useAuth();
  const [eventData, setEventData] = useState({
    name: "",
    PhotoSphere: "",
    description: "",
    imageSrc: [],
    start: "",
    end: "",
    coordinates: ["", ""],
    city: adminPlace || "",
  });

  // const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const markerRef = useRef(null);
  const mapRef = useRef(null);

  const municipalityCoordinates = {
    "Santa Cruz": [121.41466879855538, 14.281915080996798],
    Cavinti: [121.52708986296062, 14.24556685906704],
    Famy: [121.447645, 14.437787],
    Kalayaan: [121.479145, 14.32511],
    Luisiana: [121.510044, 14.185146],
    Lumban: [121.459361, 14.297124],
    Mabitac: [121.427904, 14.426752],
    Magdalena: [121.429401, 14.199537],
    Majayjay: [121.471614, 14.144784],
    Paete: [121.482694, 14.364237],
    Pagsanjan: [121.456073, 14.272613],
    Pakil: [121.478687, 14.38097],
    Pangil: [121.468441, 14.402897],
    Pila: [121.364792, 14.234284],
    "Santa Maria": [121.426853, 14.471469],
    Siniloan: [121.446098, 14.421212]
    // Add coordinates for other municipalities
  };

  const municipalityBounds = {
    "Santa Cruz": [
      [121.393089, 14.261971], // Southwest coordinates (lower longitude, lower latitude)
      [121.415749, 14.293329], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Cavinti: [
      [121.503296, 14.237097],
      [121.555138, 14.268875],
    ],
    Famy: [
      [121.434374, 14.425869],
      [121.477289, 14.453964],
    ],
    Kalayaan: [
      [121.475143, 14.321357], // Southwest coordinates (lower longitude, lower latitude)
      [121.483598, 14.330256], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Luisiana: [
      [121.50424, 14.179394], // Southwest coordinates (lower longitude, lower latitude)
      [121.521792, 14.191918], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Lumban: [
      [121.453686, 14.283764], // Southwest coordinates (lower longitude, lower latitude)
      [121.502867, 14.316451], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Mabitac: [
      [121.416178, 14.422253], // Southwest coordinates (lower longitude, lower latitude)
      [121.433988, 14.435678], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Magdalena: [
      [121.419954, 14.192292], // Southwest coordinates (lower longitude, lower latitude)
      [121.441841, 14.20806], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Majayjay: [
      [121.434116, 14.111938], // Southwest coordinates (lower longitude, lower latitude)
      [121.503983, 14.168201], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Paete: [
      [121.477032, 14.354163], // Southwest coordinates (lower longitude, lower latitude)
      [121.493554, 14.36755], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pagsanjan: [
      [121.436777, 14.259059], // Southwest coordinates (lower longitude, lower latitude)
      [121.470251, 14.28235], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pakil: [
      [121.467247, 14.374492], // Southwest coordinates (lower longitude, lower latitude)
      [121.492739, 14.388626], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pangil: [
      [121.454287, 14.391619], // Southwest coordinates (lower longitude, lower latitude)
      [121.482954, 14.413317], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Pila: [
      [121.336012, 14.219043], // Southwest coordinates (lower longitude, lower latitude)
      [121.375923, 14.251905], // Northeast coordinates (higher longitude, higher latitude)
    ],
    "Santa Maria": [
      [121.390343, 14.449143], // Southwest coordinates (lower longitude, lower latitude)
      [121.440811, 14.488038], // Northeast coordinates (higher longitude, higher latitude)
    ],
    Siniloan: [
      [121.440554, 14.408495], // Southwest coordinates (lower longitude, lower latitude)
      [121.458664, 14.424622], // Northeast coordinates (higher longitude, higher latitude)
    ]
    // Add bounding boxes for other municipalities
  };


  const addEventToFirestore = async () => {
    setUploading(true);

    // Default start time: 8:00 AM
    const defaultStartTime = new Date();
    defaultStartTime.setUTCHours(8, 0, 0, 0);

    // Default end time: 8:00 PM
    const defaultEndTime = new Date();
    defaultEndTime.setUTCHours(20, 0, 0, 0);

    // Ensure that date values are in UTC format
    const startDate = new Date(eventData.start + "Z") || defaultStartTime;
    const endDate = new Date(eventData.end + "Z") || defaultEndTime;

    // Convert the coordinates to numbers
    const [latitude, longitude] = eventData.coordinates;

    // Check if the coordinates are valid numbers
    // if (isNaN(latitude) || isNaN(longitude)) {
    //   const errorMessage =
    //     "Invalid coordinates. Please enter valid numeric values.";
    //   console.error(errorMessage);
    //   setError(errorMessage); 
    //   return;
    // }

    // // Clear the error message if coordinates are valid
    // setError("");

    // Check if a city is selected
    // if (eventData.city === "") {
    //   const errorMessage = "Please select a city.";
    //   console.error(errorMessage);
    //   setError(errorMessage);
    //   return;
    // }

    // Upload images to Firebase Storage and get download URLs
    const imageDownloadURLs = [];
    for (const imageFile of eventData.imageSrc) {
      const imageRef = ref(
        storage,
        `eventImages/${Date.now()}_${Math.random()}`
      );

      // Read the image file correctly as binary data
      const response = await fetch(imageFile); // Assuming imageFile is a valid URL or Blob
      const imageBlob = await response.blob();

      // Create a reference with a custom metadata
      const metadata = {
        contentType: imageBlob.type,
      };
      const uploadTask = uploadBytesResumable(imageRef, imageBlob, metadata);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      });

      await uploadTask;

      const downloadURL = await getDownloadURL(imageRef);
      imageDownloadURLs.push(downloadURL);
    }

    // Transform eventData into the desired format
    const formattedEventData = {
      name: eventData.name,
      PhotoSphere: eventData.PhotoSphere,
      description: eventData.description,
      imageSrc: imageDownloadURLs,
      start: {
        day: startDate.getUTCDate(),
        month: startDate.getUTCMonth() + 1,
        year: startDate.getUTCFullYear(),
        hour: startDate.getUTCHours() % 12 || 12,
        minute: startDate.getUTCMinutes(),
        isAM: startDate.getUTCHours() < 12,
      },
      end: {
        day: endDate.getUTCDate(),
        month: endDate.getUTCMonth() + 1,
        year: endDate.getUTCFullYear(),
        hour: endDate.getUTCHours() % 12 || 12,
        minute: endDate.getUTCMinutes(),
        isAM: endDate.getUTCHours() < 12,
      },
      coordinates: [latitude, longitude],
      city: eventData.city,
    };

    // Format minutes with two digits for display
    formattedEventData.start.minute = String(
      formattedEventData.start.minute
    ).padStart(2, "0");
    formattedEventData.end.minute = String(
      formattedEventData.end.minute
    ).padStart(2, "0");

    // Save the formatted event data to Firestore
    try {
      const docRef = await addDoc(collection(db, "events"), formattedEventData);
      console.log("Event saved with ID: ", docRef.id);
      setUploading(false); // Set uploading to false when done
    } catch (error) {
      console.error("Error adding document: ", error);
      setUploading(false); // Set uploading to false on error
    }
  };

  const fetchEventData = async (eventName) => {
    try {
      const touristSpotsRef = collection(db, "events");
      const q = query(touristSpotsRef, where("name", "==", eventName));
      const querySnapshot = await getDocs(q);

      const marker = markerRef.current;
      const map = mapRef.current;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Update the form fields with the retrieved data
        setEventData({
          name: data.name || "",
          PhotoSphere: data.PhotoSphere || "",
          description: data.description || "",
          imageSrc: data.imageSrc || [],
          imageAlt: data.imageAlt || "No chosen file",
          coordinates: data.coordinates || ["", ""],
          availability: data.availability || {
            days: [],
            start: {
              hours: "",
              minutes: "",
              period: "AM",
            },
            endTime: {
              hours: "",
              minutes: "",
              period: "AM",
            },
          },
          city: data.city || "",
        }); // If coordinates are available, update the marker's position
        if (data.coordinates && marker && map) {
          const [lng, lat] = data.coordinates;
          // Update the marker's position
          marker.setLngLat([parseFloat(lng), parseFloat(lat)]);
          // Update the map's cente r to the marker's position
          map.setCenter([parseFloat(lng), parseFloat(lat)]);
        }
      });
      
    } catch (error) {
      console.error("Error fetching tourist spot data: ", error);
    }
  };

  const handleGetClick = async () => {
    // Fetch the data based on the search query when the Get button is clicked
    fetchEventData(searchQuery);
    try {
      const clipboardText = await navigator.clipboard.readText();
      setSearchQuery(clipboardText);
    } catch (error) {
      console.error("Error reading clipboard text:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    setEventData({
      ...eventData,
      [name]: uppercaseValue,
      city: adminPlace || "",
    });
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const imageUrls = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setEventData({
        ...eventData,
        imageSrc: [...eventData.imageSrc, ...imageUrls],
      });
    }
  };

  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const thumbnailUrl = URL.createObjectURL(file);
      setEventData({
        ...eventData,
        imageSrc: [thumbnailUrl, ...eventData.imageSrc],
      });
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...eventData.imageSrc];
    updatedImages.splice(index, 1);
    setEventData({
      ...eventData,
      imageSrc: updatedImages,
    });
  };

  const handleSubmit = async () => {
    // Send the eventData to your server or perform any other actions here.
    // Check if any of the required fields are empty
    if (
      !eventData.name ||
      !eventData.description ||
      // eventData.imageSrc.length === 0 ||
      eventData.coordinates.some((coord) => coord === "") 
      // !eventData.city
    ) {
      // Display an error message or alert
      alert("Please fill in all the required fields before submitting.");
      return; // Prevent form submission
    }

    try {
      await addEventToFirestore();
      console.log(eventData);

      // Create a reference to the "touristSpots" collection
      const spotsCollection = collection(db, "events");

      // Check if the document already exists with the same name
      const existingSpotQuery = query(
        spotsCollection,
        where("name", "==", eventData.name)
      );

      const existingSpotSnapshot = await getDocs(existingSpotQuery);

      if (existingSpotSnapshot.size > 0) {
        // Document already exists, update the existing data
        existingSpotSnapshot.forEach(async (doc) => {
          await setDoc(doc.ref, { ...eventData }); // Set the entire document with new data
          alert("Data updated successfully!");
        });
      } else {
        // Document doesn't exist, add new data
        const docRef = await addDoc(spotsCollection, eventData);
        alert("Data submitted successfully!");
        console.log("Document written with ID: ", docRef.id);
      }
      // Clear the form after a successful submission
      setEventData({
        name: "",
        PhotoSphere: "",
        description: "",
        imageSrc: [],
        start: "",
        end: "",
        coordinates: ["", ""],
        city: "Select City",
      });
    } catch (error) {
      console.error("Error adding/updating document: ", error);
    }
  };

  const initializeMap = () => {
    mapboxgl.accessToken = mapboxAccessToken;

    const bounds = [
      [116.928977, 4.587203],
      [126.611043, 21.321048],
    ];

    const coordinates = document.getElementById("coordinates");
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/xsend/clk9t69hj001501q98ucibq70",
      center: [121.41804992973309, 14.268459088733751],
      zoom: 15,
      maxBounds: bounds,
      pitch: 50,
    });

    const marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([121.41804992973309, 14.268459088733751])
      .addTo(map);

    const onDragEnd = () => {
      const lngLat = marker.getLngLat();
      coordinates.style.display = "block";
      coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;

      // Update placeData with the new coordinates without affecting other fields
      setEventData((prevData) => ({
        ...prevData,
        coordinates: [lngLat.lng.toString(), lngLat.lat.toString()],
      }));
    };

    marker.on("dragend", onDragEnd);

    // Assign references to marker and map to the refs
    markerRef.current = marker;
    mapRef.current = map;

    return map;
  };

  useEffect(() => {
    const map = initializeMap();

    return () => map.remove();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const map = initializeMap();
  
    if (adminPlace && mapRef.current) {
      const selectedBounds = municipalityBounds[adminPlace];
  
      if (selectedBounds) {
        const zoom = 15; // Set your desired zoom level
        mapRef.current.setZoom(zoom);
        mapRef.current.setMaxBounds(selectedBounds);
  
        const markerCoordinates = municipalityCoordinates[adminPlace];
        mapRef.current.setCenter(markerCoordinates); // Set the center to marker's position
        markerRef.current.setLngLat(markerCoordinates); // Set the marker's position
  
        // Optional: Set pitch after a delay
        setTimeout(() => {
          const pitchValue = 50; // Adjust pitch as needed
          mapRef.current.setPitch(pitchValue);
        }, 2000); // Adjust the delay as needed
      }
    }
  
    return () => map.remove();
    // eslint-disable-next-line
  }, [adminPlace]);  


  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between mb-4">
        <h2 className="text-2xl font-semibold mb-4">Add Event</h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Enter Event Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 mr-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
          <button
            onClick={handleGetClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          >
            Edit
          </button>
        </div>
      </div>
      <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Municipality:
          </label>
          <select
            name="city"
            value={eventData.city}
            onChange={(e) => {
              setEventData({
                ...eventData,
                city: e.target.value,
              });
            }}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          >
            <option value={adminPlace}>{adminPlace}</option>
            {/* Add other municipality options */}
          </select>
        </div>
      <div style={{ position: "relative", height: "400px", width: "100%" }}>
        <div
          id="map"
          style={{
            position: "absolute",
            top: "0",
            bottom: "0",
            width: "100%",
            height: "100%", // Adjust the height of the map
          }}
        />
        <pre
          id="coordinates"
          className="coordinates"
          style={{
            display: "none",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            position: "absolute",
            bottom: "40px",
            left: "10px",
            padding: "5px 10px",
            margin: 0,
            fontSize: "11px",
            lineHeight: "18px",
            borderRadius: "3px",
          }}
        />
      </div>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Coordinates (Longitude):
          </label>
          <input
            type="text"
            name="coordinates"
            readOnly
            value={eventData.coordinates[0]} // Longitude
            onChange={(e) =>
              setEventData({
                ...eventData,
                coordinates: [e.target.value, eventData.coordinates[1]],
              })
            }
             className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Coordinates (Latitude):
          </label>
          <input
            type="text"
            name="coordinates"
            readOnly
            value={eventData.coordinates[1]} // Latitude
            onChange={(e) =>
              setEventData({
                ...eventData,
                coordinates: [eventData.coordinates[0], e.target.value],
              })
            }
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="text-gray-600">Event Name:</label>
        <input
          type="text"
          name="name"
          value={eventData.name}
          onChange={handleInputChange}
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-600">Description:</label>
        <input
          type="text"
          name="description"
          value={eventData.description}
          onChange={handleInputChange}
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            360 image src:
          </label>
          <input
            type="text"
            name="PhotoSphere"
            placeholder="optional"
            value={eventData.PhotoSphere}
            onChange={handleInputChange}
            className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>
      <div className="mb-4">
        <label className="text-gray-600">Thumbnail Image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleThumbnailUpload}
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-600">Image Source:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          multiple
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
        {eventData.imageSrc.length > 0 && (
          <div className="mt-2">
            {eventData.imageSrc.map((imageUrl, index) => (
              <div key={index} className="flex items-center gap-2">
                <img
                  src={imageUrl}
                  alt={`Event Img ${index + 1}`}
                  style={{ maxWidth: "100px", marginRight: "10px" }}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mb-4">
        <label className="text-gray-600">Start Date and Time:</label>
        <input
          type="datetime-local"
          name="start"
          value={eventData.start}
          onChange={handleInputChange}
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-600">End Date and Time:</label>
        <input
          type="datetime-local"
          name="end"
          value={eventData.end}
          onChange={handleInputChange}
          className="block w-full mt-1 border rounded-md px-3 py-2 shadow-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full m-2"
      >
        Add Event
      </button>
      {/* Upload progress indicator */}
      {uploading && (
        <div>
          <div className="text-blue-600">Uploading...</div>
          <div>{`${uploadProgress.toFixed(2)}%`}</div>
        </div>
      )}
    </div>
  );
}

export default AdminFestivities;
