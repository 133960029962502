import React, { useEffect, useState, useRef, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxAccessToken from "../data/mapboxAccessToken";
// import tourGuideInstructions from "../data/Instructions.json";
import PinHolder from "../components/PinHolder";
import Sticker1 from "../images/1.png";
import Sticker2 from "../images/2.png";
import Sticker3 from "../images/3.png";
import Sticker4 from "../images/4.png";
import { createRoot } from "react-dom/client"; // Updated import
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Slideover from "./Slideover";
import "./MapBoxMap.css";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import firebaseConfig from "../data/firebaseConfig";
import { getDatabase, ref, onValue } from "firebase/database";
const app = initializeApp(firebaseConfig);
const db1 = getFirestore(app);
const db2 = getFirestore(app);
const db3 = getFirestore(app);
const db4 = getFirestore(app);

const MapboxMap = () => {
  const map = useRef(null);
  const [showTourGuide, setShowTourGuide] = useState(true);
  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
  // eslint-disable-next-line
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(
    "mapbox://styles/xsend/clk9t88s8004g01rddzdsfclr"
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touristSpots, setTouristSpots] = useState([]);
  const [delicaciesPlaces, setDelicaciesPlaces] = useState([]);
  const [accommodationPlaces, setAccommodationPlaces] = useState([]);
  const [puvsPlaces, setPuvsPlaces] = useState([]);
  const [tourGuideInstructions, setTourGuideInstructions] = useState([]);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const db = getDatabase(app);
    const instructionsRef = ref(db, "Instructions"); // Replace 'chatbot' with your Firebase database reference

    // Fetch data from Firebase Realtime Database
    onValue(instructionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Update state with fetched data
        setTourGuideInstructions(data);
      }
    });
  }, []);

  // Load selected map style from localStorage on component mount
  useEffect(() => {
    const savedStyle = localStorage.getItem("selectedMapStyle");
    if (savedStyle) {
      setSelectedStyle(savedStyle);
    }
  }, []);

  // Function to handle change in map style
  const handleStyleChange = (style) => {
    setSelectedStyle(style);
    // Save selected map style to localStorage
    localStorage.setItem("selectedMapStyle", style);
  };

  // Function to store markers in localStorage
  const saveMarkersToLocalStorage = (markers) => {
    localStorage.setItem("markers", JSON.stringify(markers));
  };

  // Define fetchData using useCallback
  const fetchData = useCallback(async (collectionRef, setterFunction) => {
    try {
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setterFunction(data);
      // Also, consider saving fetched markers to local storage if needed
      saveMarkersToLocalStorage(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error: Could show an error message or retry fetching data
    }
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchData(collection(db1, "touristSpots"), setTouristSpots),
        fetchData(collection(db2, "delicaciesPlaces"), setDelicaciesPlaces),
        fetchData(
          collection(db3, "accommodationPlaces"),
          setAccommodationPlaces
        ),
        fetchData(collection(db4, "puvsPlaces"), setPuvsPlaces),
      ]);
    };
    fetchAllData();
  }, [fetchData]); // Include fetchData as a dependency

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    //map
    const bounds = [
      [116.928977, 4.587203], // Southwest coordinates (lower longitude, lower latitude)
      [126.611043, 21.321048], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current = new mapboxgl.Map({
      container: "map", // container ID
      style: selectedStyle,
      center: [121.41804992973309, 14.268459088733751],
      zoom: 16,
      maxBounds: bounds,
      pitch: 50,
    });

    //pin on click
    const handleMarkerClick = (location, popup) => {
      setSelectedMarker(location);
      const popupDiv = document.createElement("div");
      createRoot(popupDiv).render(
        <PinHolder location={location} setShowPinHolder={setSelectedMarker} />
      );
      popup.setDOMContent(popupDiv);
    };

    const addMarkers = (locations) => {
      locations.forEach((location) => {
        const popup = new mapboxgl.Popup({ offset: 25 }).setText(location.name);
        const marker = document.createElement("div");
        marker.className = "custom-marker";

        // Create a blurred placeholder image while the actual image is loading
        const placeholderImg = new Image();
        placeholderImg.src = `${location.imageSrc}`;
        placeholderImg.onload = () => {
          marker.style.backgroundImage = `url(${location.imageSrc})`;
        };
        placeholderImg.onerror = () => {
          // Handle error if the placeholder image fails to load
          console.error("Error loading placeholder image");
        };

        marker.style.backgroundSize = "cover";
        marker.style.width = "40px"; // Adjust width as needed
        marker.style.height = "40px"; // Adjust height as needed
        marker.style.borderRadius = "50%";
        marker.style.cursor = "pointer";
        marker.style.border = "1px solid #0080FE";
        marker.style.transform = "rotate(45deg)"; // Rotate the marker shape

        const newMarker = new mapboxgl.Marker(marker)
          .setLngLat(location.coordinates)
          .setPopup(popup);

        if (map.current) {
          newMarker
            .addTo(map.current)
            .getElement()
            .addEventListener("click", () =>
              handleMarkerClick(location, popup)
            );
        }
      });
    };

    const allMarkers = [
      ...touristSpots,
      ...delicaciesPlaces,
      ...accommodationPlaces,
      ...puvsPlaces,
    ];

    // Add markers for different places
    addMarkers(touristSpots);
    addMarkers(delicaciesPlaces);
    addMarkers(accommodationPlaces);
    addMarkers(puvsPlaces);

    // Save markers to localStorage
    saveMarkersToLocalStorage(allMarkers);

    //removed markers of individual places

    // Search
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: "ph",
    });

    map.current.addControl(geocoder, "top-left");

    geocoder.on("result", (e) => {
      const { coordinates } = e.result.geometry;

      map.current.flyTo({
        center: coordinates,
        zoom: 18,
        pitch: 50,
        speed: 1.2,
      });
    });

    return () => {
      map.current.remove();
    };
  }, [
    selectedStyle,
    delicaciesPlaces,
    touristSpots,
    accommodationPlaces,
    puvsPlaces,
  ]);

  const mapContainerStyle = {
    position: "relative",
    top: 0,
    bottom: 0,
    width: "100%",
  };

  let speech;

  const speakText = (text) => {
    if (!isMuted && window.speechSynthesis) {
      // Cancel any ongoing speech
      window.speechSynthesis.cancel();

      // Create new speech synthesis utterance
      const speech = new SpeechSynthesisUtterance();
      speech.text = text;
      speech.lang = "en-US"; // Set the language to Filipino

      // Filter voices to find a female one (if available)
      const voices = window.speechSynthesis.getVoices();
      const femaleVoice = voices.find((voice) => {
        return voice.lang === "en-US" && voice.gender === "female";
      });

      if (femaleVoice) {
        speech.voice = femaleVoice;
      }
      window.speechSynthesis.speak(speech);
    }
  };

  const handleMuteToggle = () => {
    const newMuted = !isMuted;
    setIsMuted(newMuted);
    if (speech) {
      speech.volume = newMuted ? 0 : 1;
    }
  };

  const renderMuteButton = () => {
    return (
      <Button onClick={handleMuteToggle}>{isMuted ? "Unmute" : "Mute"}</Button>
    );
  };

  const handleNextInstruction = () => {
    if (currentInstructionIndex < tourGuideInstructions.length - 1) {
      setCurrentInstructionIndex((prevIndex) => prevIndex + 1);

      setCurrentImageIndex((prevIndex) => {
        // Logic to change the image index based on the current instruction index
        // For example:
        return prevIndex + 1; // Incrementing the index by 1
      });

      // Speech synthesis for the current instruction
      speakText(tourGuideInstructions[currentInstructionIndex + 1]);
      if (currentInstructionIndex + 1 === 2) {
        // If it's the third instruction, trigger the zoom to Santa Cruz
        handleMoveToPila();
      }
      if (currentInstructionIndex + 1 === 4) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleZoomToSantaCruz();
      }
      if (currentInstructionIndex + 1 === 6) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToPagsanjan();
      }
      if (currentInstructionIndex + 1 === 8) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToMagdalena();
      }
      if (currentInstructionIndex + 1 === 10) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToMajayjay();
      }
      if (currentInstructionIndex + 1 === 12) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToLuisiana();
      }
      if (currentInstructionIndex + 1 === 14) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToCavinti();
      }
      if (currentInstructionIndex + 1 === 16) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToLumban();
      }
      if (currentInstructionIndex + 1 === 18) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToKalayaan();
      }
      if (currentInstructionIndex + 1 === 20) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToPaete();
      }
      if (currentInstructionIndex + 1 === 22) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToPakil();
      }
      if (currentInstructionIndex + 1 === 24) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToPangil();
      }
      if (currentInstructionIndex + 1 === 26) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToSiniloan();
      }
      if (currentInstructionIndex + 1 === 28) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToFamy();
      }
      if (currentInstructionIndex + 1 === 30) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToMabitac();
      }
      if (currentInstructionIndex + 1 === 32) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToSantaMaria();
      }
      if (currentInstructionIndex + 1 === 34) {
        // If it's the fourth instruction, trigger the move to Cavinti
        handleMoveToFree();
        removeBounds();
      }
    }
  };

  const getImageForIndex = (index) => {
    // Logic to return the image source based on the index
    // For example:
    switch (index) {
      case 0:
        return Sticker1;
      case 1:
        return Sticker2;
      case 2:
        return Sticker4;
      case 3:
        return Sticker2;
      case 4:
        return Sticker4;
      case 5:
        return Sticker2;
      case 6:
        return Sticker4;
      case 7:
        return Sticker2;
      case 8:
        return Sticker4;
      case 9:
        return Sticker2;
      case 10:
        return Sticker4;
      case 11:
        return Sticker2;
      case 12:
        return Sticker4;
      case 13:
        return Sticker2;
      case 14:
        return Sticker4;
      case 15:
        return Sticker2;
      case 16:
        return Sticker4;
      case 17:
        return Sticker2;
      case 18:
        return Sticker4;
      case 19:
        return Sticker2;
      case 20:
        return Sticker4;
      case 21:
        return Sticker2;
      case 22:
        return Sticker4;
      case 23:
        return Sticker2;
      case 24:
        return Sticker4;
      case 25:
        return Sticker2;
      case 26:
        return Sticker4;
      case 27:
        return Sticker2;
      case 28:
        return Sticker4;
      case 29:
        return Sticker2;
      case 30:
        return Sticker4;
      case 31:
        return Sticker2;
      case 32:
        return Sticker4;
      case 33:
        return Sticker2;
      case 34:
        return Sticker4;
      case 35:
        return Sticker3;
      // Add cases for other images...
      default:
        return Sticker1; // Default image if index is out of range
    }
  };

  const removeBounds = () => {
    map.current.setMaxBounds(null); // Set bounds to null to remove it
    map.current.setMinZoom(10.5); // Set your desired minimum zoom level after removing bounds
  };

  const handleZoomToSantaCruz = () => {
    // Get the coordinates of Santa Cruz or use the desired coordinates
    const santaCruzCoordinates = [121.406093, 14.269915];

    // Zoom to Santa Cruz
    map.current.easeTo({
      center: santaCruzCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    const bounds = [
      [121.393089, 14.261971], // Southwest coordinates (lower longitude, lower latitude)
      [121.415749, 14.293329], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToFamy = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.453235, 14.439771];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.446304, 14.432852], // Southwest coordinates (lower longitude, lower latitude)
      [121.478062, 14.461444], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToKalayaan = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.479145, 14.32511];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.475143, 14.321357], // Southwest coordinates (lower longitude, lower latitude)
      [121.483598, 14.330256], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToLuisiana = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.510044, 14.185146];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.50424, 14.179394], // Southwest coordinates (lower longitude, lower latitude)
      [121.521792, 14.191918], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToLumban = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.459361, 14.297124];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.453686, 14.283764], // Southwest coordinates (lower longitude, lower latitude)
      [121.502867, 14.316451], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToMabitac = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.427904, 14.426752];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.416178, 14.422253], // Southwest coordinates (lower longitude, lower latitude)
      [121.433988, 14.435678], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToMagdalena = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.429401, 14.199537];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.419954, 14.192292], // Southwest coordinates (lower longitude, lower latitude)
      [121.441841, 14.20806], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToMajayjay = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.471614, 14.144784];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.434116, 14.111938], // Southwest coordinates (lower longitude, lower latitude)
      [121.503983, 14.168201], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToPaete = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.482694, 14.364237];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.477032, 14.354163], // Southwest coordinates (lower longitude, lower latitude)
      [121.493554, 14.36755], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToPagsanjan = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.456073, 14.272613];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.436777, 14.259059], // Southwest coordinates (lower longitude, lower latitude)
      [121.470251, 14.28235], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToPakil = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.478687, 14.38097];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.467247, 14.374492], // Southwest coordinates (lower longitude, lower latitude)
      [121.492739, 14.388626], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToPangil = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.468441, 14.402897];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.454287, 14.391619], // Southwest coordinates (lower longitude, lower latitude)
      [121.482954, 14.413317], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToPila = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.364792, 14.234284];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.336012, 14.219043], // Southwest coordinates (lower longitude, lower latitude)
      [121.375923, 14.251905], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToSantaMaria = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.426853, 14.471469];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.390343, 14.449143], // Southwest coordinates (lower longitude, lower latitude)
      [121.440811, 14.488038], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToSiniloan = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.446098, 14.421212];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.440554, 14.408495], // Southwest coordinates (lower longitude, lower latitude)
      [121.458664, 14.424622], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToCavinti = () => {
    // Get the coordinates of Cavinti or use the desired coordinates
    const cavintiCoordinates = [121.505195, 14.247298];

    // Move to Cavinti
    map.current.easeTo({
      center: cavintiCoordinates,
      zoom: 15, // Set the desired zoom level
      speed: 8, // Adjust the flying speed as needed
    });

    // Define bounds for Cavinti or adjust as needed
    const bounds = [
      [121.486816, 14.22495], // Southwest coordinates (lower longitude, lower latitude)
      [121.57711, 14.2915], // Northeast coordinates (higher longitude, higher latitude)
    ];

    map.current.setMaxBounds(bounds);
    map.current.setMinZoom(12);
  };

  const handleMoveToFree = () => {
    // Get the coordinates for Free Roam or use the desired coordinates
    const freeRoamCoordinates = [121.41804992973309, 14.268459088733751];

    // Move to Free Roam coordinates
    map.current.easeTo({
      center: freeRoamCoordinates,
      zoom: 14,
      speed: 8,
    });
  };

  const handleResetMap = () => {
    // // Set the default coordinates or initial view of the map
    // const bounds = [
    //   [116.928977, 4.587203], // Southwest coordinates (lower longitude, lower latitude)
    //   [126.611043, 21.321048], // Northeast coordinates (higher longitude, higher latitude)
    // ];

    // // Reset the map to the default coordinates and zoom
    // map.current.easeTo({
    //   center: [121.41804992973309, 14.268459088733751],
    //   zoom: 16,
    //   maxBounds: bounds,
    //   pitch: 50,
    // });
    // Remove any set bounds or adjustments made during the tour guide
    removeBounds(); // Assuming this function removes any set bounds
  };

  const handleCloseTourGuide = () => {
    setShowTourGuide(false);
    handleResetMap(); // Reset the map when the tour guide is closed
  };

  const toggleInstructions = () => {
    setShowTourGuide((prevShowTourGuide) => !prevShowTourGuide);
  };

  const renderCloseButton = () => {
    return <Button onClick={handleCloseTourGuide}>Close</Button>;
  };

  return (
    <>
      <div id="map" style={mapContainerStyle}>
        {/* content inside map */}
        <div className="flex justify-end px-1">
          <FormControl
            sx={{ m: 1, minWidth: 80, backgroundColor: "#1589FF", zIndex: 1 }}
          >
            <InputLabel id="style-select-label" sx={{ color: "white" }}>
              Map Style
            </InputLabel>
            <Select
              labelId="style-select-label"
              id="style-select"
              value={selectedStyle}
              label="Map Style"
              onChange={(event) => handleStyleChange(event.target.value)}
              sx={{ color: "white" }}
            >
              <MenuItem value="mapbox://styles/xsend/clk9t88s8004g01rddzdsfclr">
                Satellite
              </MenuItem>
              <MenuItem value="mapbox://styles/xsend/clk9t69hj001501q98ucibq70">
                3D
              </MenuItem>
              {/* Add more MenuItem components for different styles if needed */}
            </Select>
          </FormControl>
        </div>
        <button
          className="toggle-instructions-button"
          onClick={toggleInstructions}
        >
          {showTourGuide ? "Hide Instructions" : "Show Instructions"}
        </button>

        {showTourGuide && (
          <div className="tour-guide">
            <div className="tour-guide-content">
              {currentInstructionIndex === 0 && (
                <>
                  <div className="tour-guide-sticker">
                    <img
                      src={getImageForIndex(currentImageIndex)}
                      alt="Tour Guide"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <p className="text-justify">{tourGuideInstructions[0]}</p>
                  <Button onClick={handleNextInstruction}>Next</Button>
                  <Button
                    onClick={() => {
                      setShowTourGuide(false);
                    }}
                  >
                    Close
                  </Button>
                  {renderMuteButton()}
                </>
              )}
              {currentInstructionIndex > 0 && (
                <>
                  <div className="tour-guide-sticker">
                    <img
                      src={getImageForIndex(currentImageIndex)}
                      alt="Tour Guide"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <p className="text-justify">
                    {tourGuideInstructions[currentInstructionIndex]}
                  </p>
                  {currentInstructionIndex <
                    tourGuideInstructions.length - 1 && (
                    <Button onClick={handleNextInstruction}>Next</Button>
                  )}
                  {/* Add close button for all instructions except the last one */}
                  {currentInstructionIndex !==
                    tourGuideInstructions.length - 1 && renderCloseButton()}
                  {currentInstructionIndex ===
                    tourGuideInstructions.length - 1 && (
                    <Button onClick={() => setShowTourGuide(false)}>
                      Close
                    </Button>
                  )}
                  {renderMuteButton()}
                </>
              )}
            </div>
          </div>
        )}
        <Slideover />
      </div>
      {/* content outside map */}
    </>
  );
};

export default MapboxMap;
