import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  TextareaAutosize,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../data/firebaseConfig";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";

function Registration({ open, handleClose }) {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  const [storeName, setStoreName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [municipality, setMunicipality] = useState("SELECT MUNICIPALITY");
  const [type, setType] = useState("SELECT");
  const [submitted, setSubmitted] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState("");

  // Fetch user's existing registration data from Firestore
  useEffect(() => {
    const user = auth.currentUser;

    if (open && user) {
      const userRegistrationRef = doc(db, "registrations", user.uid);

      getDoc(userRegistrationRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // Populate state with existing data
            setStoreName(userData.storeName || "");
            setDescription(userData.description || "");
            setMunicipality(userData.municipality || "SELECT MUNICIPALITY");
            setType(userData.type || "SELECT");
            // Set existing images to state for preview
            setImagePreviews(userData.imageUrls || []);
            setRegistrationStatus(userData.status || "");

            // Mark that data has been submitted
            setSubmitted(true);
          } else {
            // Reset form fields if no data exists for the user
            setStoreName("");
            setDescription("");
            setMunicipality("SELECT MUNICIPALITY");
            setType("SELECT");
            setImagePreviews([]);

            // Reset submitted status
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching existing data:", error);
        });
    }
  }, [open, auth, db]);

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  const handleStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages([...images, ...selectedImages]);

    const previews = [];
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        previews.push(event.target.result);
        setImagePreviews([...imagePreviews, event.target.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleMunicipalityChange = (e) => {
    setMunicipality(e.target.value); // Handle municipality change
  };

  const handleTypeChange = (e) => {
    setType(e.target.value); // Handle municipality change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the current user
    const user = auth.currentUser;
    const userEmail = user.email;

    // Create a new document reference in Firestore for the user's registration
    const userRegistrationRef = doc(db, "registrations", user.uid);

    try {
      // Set the user's registration data in Firestore
      await setDoc(userRegistrationRef, {
        storeName,
        description,
        municipality,
        type,
        status: "pending",
        userEmail,
        // Add other registration data as needed
      });

      // Save images to Firebase Storage
      const storageRef = ref(storage, `users_proof/${user.uid}`);

      // Array to hold the image download URLs
      const imageUrls = [];

      // Loop through each image to upload it to Firebase Storage
      for (const image of images) {
        const imageRef = ref(storageRef, image.name);
        await uploadBytes(imageRef, image);

        // Get the download URL for each uploaded image
        const url = await getDownloadURL(imageRef);
        imageUrls.push(url);
      }

      // Save image URLs into the Firestore document
      await setDoc(userRegistrationRef, { imageUrls }, { merge: true });

      // Reset form values and close the dialog
      setStoreName("");
      setDescription("");
      setImages([]);
      handleClose();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  if (registrationStatus === "approved" && open) {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <div className="bg-white rounded-lg p-6">
          <DialogTitle className="text-lg font-semibold text-center">
            Registration Approved
          </DialogTitle>
          <DialogContent className="space-y-4">
            <Typography variant="body1">
              Congratulations! Your registration has been approved.
            </Typography>
            {/* Display approved data or any additional information */}
            <Typography variant="body1">Store Name: {storeName}</Typography>
            {/* Add other fields */}
          </DialogContent>
          <DialogActions className="flex justify-center">
            <Button
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  if (submitted && open) {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <div className="bg-white rounded-lg p-6">
          <DialogTitle className="text-lg font-semibold text-center">
            Already Submitted
          </DialogTitle>
          <DialogContent className="space-y-4">
            <Typography variant="body1">
              You have already submitted the registration.
            </Typography>
            {/* Display submitted data here */}
            <Typography variant="body1">
              PLEASE WAIT FOR THE CONFIRMATION OF: {storeName}
            </Typography>
            {/* Add other fields */}
          </DialogContent>
          <DialogActions className="flex justify-center">
            <Button
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Accommodation/Delicacies Store</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {/* Selector */}
          <InputLabel id="city">Municipality</InputLabel>
          <Select
            labelId="city"
            id="city"
            value={municipality}
            onChange={handleMunicipalityChange}
            fullWidth
            style={{
              marginBottom: "1rem",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Adding shadow
              borderRadius: "4px",
            }}
          >
            <MenuItem value="SELECT MUNICIPALITY">SELECT MUNICIPALITY</MenuItem>
            <MenuItem value="Santa Cruz">Santa Cruz</MenuItem>
            <MenuItem value="Cavinti">Cavinti</MenuItem>
            <MenuItem value="Famy">Famy</MenuItem>
            <MenuItem value="Kalayaan">Kalayaan</MenuItem>
            <MenuItem value="Luisiana">Luisiana</MenuItem>
            <MenuItem value="Lumban">Lumban</MenuItem>
            <MenuItem value="Mabitac">Mabitac</MenuItem>
            <MenuItem value="Magdalena">Magdalena</MenuItem>
            <MenuItem value="Majayjay">Majayjay</MenuItem>
            <MenuItem value="Paete">Paete</MenuItem>
            <MenuItem value="Pagsanjan">Pagsanjan</MenuItem>
            <MenuItem value="Pakil">Pakil</MenuItem>
            <MenuItem value="Pangil">Pangil</MenuItem>
            <MenuItem value="Pila">Pila</MenuItem>
            <MenuItem value="Santa Maria">Santa Maria</MenuItem>
            <MenuItem value="Siniloan">Siniloan</MenuItem>
            {/* Add other options as needed */}
          </Select>

          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type"
            id="type"
            value={type}
            onChange={handleTypeChange}
            fullWidth
            style={{
              marginBottom: "1rem",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Adding shadow
              borderRadius: "4px",
            }}
          >
            <MenuItem value="SELECT">SELECT</MenuItem>
            <MenuItem value="Accommodation">Accommodation</MenuItem>
            <MenuItem value="Delicacies">Delicacies</MenuItem>
            {/* Add other options as needed */}
          </Select>

          <TextField
            autoFocus
            margin="dense"
            id="storeName"
            label="Store/Accommodation Name"
            type="text"
            fullWidth
            value={storeName}
            onChange={handleStoreNameChange}
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Adding shadow
              borderRadius: "4px", // Optional: Add border-radius for rounded corners
            }}
          />

          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Description"
            value={description}
            onChange={handleDescriptionChange}
            style={{
              width: "100%",
              marginTop: "1rem",
              marginBottom: "1rem",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Adding shadow
              borderRadius: "4px", // Optional: Add border-radius for rounded corners
              padding: "8px", // Optional: Add padding for better spacing
            }}
          />
          {/* Image Upload */}
          <Typography variant="body1" component="div" gutterBottom>
            Proof of Ownership:
          </Typography>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="imageUpload"
          />
          <label htmlFor="imageUpload">
            <IconButton component="span">
              <AddPhotoAlternateIcon />
            </IconButton>
          </label>
          <Box display="flex" flexDirection="column" mt={2}>
            {imagePreviews.map((preview, index) => (
              <Box key={index} mt={1} display="flex" alignItems="center">
                <img
                  src={preview}
                  alt={`Preview ${index}`}
                  style={{ width: "100px", height: "auto" }}
                />
                <IconButton onClick={() => removeImage(index)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          {/* Other form fields */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default Registration;
