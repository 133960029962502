import React, { useState, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../data/firebaseConfig";

export default function UserForm() {
  const navigate = useNavigate();
  const initialFormData = {
    username: "",
    about: "",
    firstName: "",
    lastName: "",
    gender: "Prefer not to say",
    streetAddress: "",
    city: "",
    region: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // State variable to store the URL of the profile photo
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const [userProfilePhotoURL, setUserProfilePhotoURL] = useState("");
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  useEffect(() => {
    // Ensure the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // Redirect to the login page or handle unauthenticated users
        navigate("/login");
      } else {
        // Fetch and pre-fill existing user data
        const userDataRef = doc(db, "users", user.uid, "userData", "document");

        getDoc(userDataRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              // Pre-fill the formData state with existing user data
              setFormData(userData);
            }
          })
          .catch((error) => {
            console.error("Error fetching user data: ", error);
          });

        // Check if the user has a profile photo and retrieve its URL
        const storageRef = ref(storage, `user-profile-photos/${user.uid}`);
        getDownloadURL(storageRef)
          .then((downloadURL) => {
            setUserProfilePhotoURL(downloadURL);
          })
          .catch((error) => {
            console.error("Error fetching profile photo: ", error);
          });
      }
    });

    return () => {
      // Unsubscribe from the auth state listener when the component unmounts
      unsubscribe();
    };
  }, [auth, navigate, db, storage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProfilePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a reference to the storage location where you want to store the profile photo
      const storageRef = ref(
        storage,
        `user-profile-photos/${auth.currentUser.uid}`
      );

      try {
        // Set the profile photo URL for preview
        setProfilePhotoURL(URL.createObjectURL(file));

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL for the uploaded photo
        const downloadURL = await getDownloadURL(storageRef);

        // Update the state with the profile photo URL
        setProfilePhotoURL(downloadURL);
      } catch (error) {
        console.error("Error uploading profile photo: ", error);
      }
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const requiredFields = [
      { field: "username", label: "Username" },
      { field: "firstName", label: "First Name" },
      { field: "lastName", label: "Last Name" },
      { field: "streetAddress", label: "Street Address" },
      { field: "city", label: "City" },
      { field: "region", label: "Region" },
    ];

    // Find all missing required fields
    const missingFields = requiredFields.filter(
      (field) => !formData[field.field]
    );

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields
        .map((field) => field.label)
        .join(", ");
      alert(`Please fill out the following fields: ${missingFieldNames}`);
      return;
    }

    const user = auth.currentUser;

    // Reference to the user's document under "userData"
    const userFormDataRef = doc(db, "users", user.uid, "userData", "document");

    // Add the profile photo URL to the form data
    formData.profilePhotoURL = profilePhotoURL;

    // Check if the document exists
    const userFormDataSnapshot = await getDoc(userFormDataRef);

    if (userFormDataSnapshot.exists()) {
      // If the document exists, update it
      await setDoc(userFormDataRef, formData, { merge: true });
      console.log("User data updated successfully!");
    } else {
      // If the document doesn't exist, create a new one
      await setDoc(userFormDataRef, formData);
      console.log("User data saved successfully!");
    }

    navigate("/userdata");
  };

  return (
    <div
      style={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "40px",
        marginBottom: "70px",
      }}
    >
      <form className="pb-12" onSubmit={handleSave}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you
              share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Username
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                      Tourist/
                    </span>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6`}
                      placeholder="Nickname"
                      value={formData.username}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    defaultValue={""}
                    value={formData.about}
                    onChange={handleInputChange}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Write a few sentences about yourself.
                </p>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Profile Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  {profilePhotoURL ? (
                    <img
                      src={profilePhotoURL}
                      alt="Profile Preview"
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  ) : userProfilePhotoURL ? (
                    <img
                      src={userProfilePhotoURL}
                      alt="Profile"
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  ) : (
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                  <input
                    type="file"
                    id="profile-photo"
                    name="profile-photo"
                    accept="image/*"
                    className={`sr-only`}
                    onChange={handleProfilePhotoChange}
                  />
                  <label
                    htmlFor="profile-photo"
                    className={`rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                  >
                    Change
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="given-name"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="family-name"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Gender
                </label>
                <div className="mt-2">
                  <select
                    id="gender"
                    name="gender"
                    autoComplete="gender-name"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.gender}
                    onChange={handleInputChange}
                  >
                    <option>Prefer not to say</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="streetAddress"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Street
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    autoComplete="streetAddress"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  State / Province
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autoComplete="address-level1"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    value={formData.region}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-center gap-x-6">
          <button
            type="submit"
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
