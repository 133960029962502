import React, { useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PinHolder.css";
import { Button, IconButton } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

function PinHolder({ location, setShowPinHolder }) {
  const { name, description, imageSrc, coordinates, PhotoSphere } = location;
  const speechRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const speakContent = () => {
    let contentToSpeak = name;

    if (description && description.trim() !== "") {
      contentToSpeak += `. ${description}`;
    }

    if (speechRef.current) {
      window.speechSynthesis.cancel();
    }

    speechRef.current = new SpeechSynthesisUtterance(contentToSpeak);
    window.speechSynthesis.speak(speechRef.current);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage("");
    setShowModal(false);
  };

  return (
    <div className="pin-holder-container">
      <Card style={{ width: "18rem" }}>
        <Card.Img
          variant="top"
          src={imageSrc[0]}
          style={{ objectFit: "cover", height: "100px", cursor: "pointer" }}
          onClick={() => handleImageClick(imageSrc[0])}
        />
        <Card.Body>
          <Card.Title>
            {name}
            <IconButton onClick={() => speakContent()}>
              <VolumeUpIcon />
            </IconButton>
          </Card.Title>
          <Card.Text className="text-justify">{description}</Card.Text>
          <Button
            variant="contained"
            onClick={() => setShowPinHolder(null)}
            href={`/Navigation?coordinates=${coordinates}`}
          >
            Get Directions
          </Button>
        </Card.Body>
      </Card>

      {/* Modal to display selectedImage */}
      <Modal show={showModal && !PhotoSphere} onHide={handleCloseModal} centered>
        <Modal.Body style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src={selectedImage}
            alt="Selected Img"
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>

      {/* Modal to display imageSphere */}
      {PhotoSphere && (
        <Modal show={showModal && PhotoSphere} onHide={handleCloseModal} centered>
          <iframe
            title="360-degree Image"
            width="100%"
            height="500px" // Adjust the height as needed
            style={{ border: "none" }}
            src={PhotoSphere}
            frameborder="0"
            allowfullscreen = "true"
            allow="VR"
            allowvr = "true"
          ></iframe>
        </Modal>
      )}
    </div>
  );
}

export default PinHolder;
