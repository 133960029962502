import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import Boy from "../images/boy.png";
import { initializeApp } from "firebase/app";
import useAuth from "../ProtectedRoute/useAuth";
import {
  addDoc,
  collection,
  query,
  getFirestore,
  onSnapshot,
  deleteDoc,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import firebaseConfig from "../data/firebaseConfig";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Comment = ({
  comment,
  onDelete,
  user,
  username,
  userProfilePhotoURL,
}) => (
  <div className="border rounded-md p-2 ml-2 my-2">
    <div className="flex gap-2 items-center">
      <img
        src={userProfilePhotoURL || Boy}
        className="object-cover w-8 h-8 rounded-full border-2 border-emerald-400 shadow-emerald-400"
        alt="User Avatar"
      />
      <h3 className="font-bold" style={{ fontSize: "18px" }}>
        {username}
      </h3>
    </div>
    <Rating name="size-small" value={comment.rating} size="small" readOnly />
    <p className="text-gray-600 mt-2">{comment.text}</p>
    {user && comment.isOwner(user) && (
      <button
        onClick={() => onDelete(comment.id)}
        className="bg-red-500 text-white px-2 py-1 rounded-md"
      >
        Delete
      </button>
    )}
  </div>
);

const DiscussionFestivities = ({ placeId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [rating, setRating] = useState(0);
  const { user } = useAuth();
  const [userProfilePhotoURL, setUserProfilePhotoURL] = useState("");
  const [userHasSubmitted, setUserHasSubmitted] = useState(false);
  // eslint-disable-next-line
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Query the comments subcollection for the specific place
    const commentsQuery = query(
      collection(db, "events", placeId, "comments")
    );

    // Add a snapshot listener to update comments in real-time
    const unsubscribe = onSnapshot(commentsQuery, (snapshot) => {
      const commentsData = [];
      snapshot.forEach((doc) => {
        const commentData = doc.data();
        commentsData.push({
          id: doc.id,
          ...commentData,
          isOwner: (currentUser) => currentUser.uid === commentData.userId,
        });
      });
      setComments(commentsData);
    });

    // Fetch the user's username and profilePhotoURL from the "userData" document
    if (user) {
      const userDataRef = doc(db, "users", user.uid, "userData", "document");
      getDoc(userDataRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUsername(userData.username);
            setUserProfilePhotoURL(userData.profilePhotoURL); // Set the profile photo URL
          }
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }

    // Check if the user has already submitted a comment and rating
    if (user) {
      const userCommentQuery = query(
        collection(db, "events", placeId, "comments"),
        where("userId", "==", user.uid)
      );

      onSnapshot(userCommentQuery, (snapshot) => {
        if (!snapshot.empty) {
          setUserHasSubmitted(true);
        }
      });
    }

    return () => unsubscribe();
  }, [placeId, user]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!user) {
      // User is not authenticated, redirect them to the login page
      window.location.href = "/login"; // You can replace this with your actual login route
      return;
    }

    if (userHasSubmitted) {
      // If the user has already submitted a comment and rating, prevent them from submitting another
      alert("You have already submitted a comment and rating for this place.");
      return;
    }

    try {
      // Fetch the user's username from the "userData" document
      const userDataRef = doc(db, "users", user.uid, "userData", "document");
      const userDataSnapshot = await getDoc(userDataRef);
      if (userDataSnapshot.exists()) {
        const userData = userDataSnapshot.data();
        const username = userData.username;

        // Create a new comment document in Firestore with the correct username
        const commentData = {
          username: username, // Use the fetched username
          profilePhotoURL: userProfilePhotoURL,
          userId: user.uid, // Store the user's ID in the comment
          text: newComment,
          rating: rating,
          timestamp: new Date(),
        };

        await addDoc(
          collection(db, "events", placeId, "comments"),
          commentData
        );

        setNewComment("");
        setRating(0);
        setUserHasSubmitted(true);
      } else {
        console.error("User data not found");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    // Check if the user is the owner of the comment before allowing deletion
    const commentToDelete = comments.find(
      (comment) => comment.id === commentId
    );

    if (commentToDelete && commentToDelete.isOwner(user)) {
      try {
        // Delete the comment document in Firestore
        const commentRef = doc(
          db,
          "events",
          placeId,
          "comments",
          commentId
        );
        await deleteDoc(commentRef);
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <h3 className="font-bold" style={{ fontSize: "23px" }}>
          Comment & Ratings
        </h3>
      </div>
      <div className="max-h-80 overflow-y-auto">
        <div className="flex flex-col">
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              onDelete={handleDeleteComment}
              user={user}
              username={comment.username}
              userProfilePhotoURL={comment.profilePhotoURL}
            />
          ))}
        </div>
      </div>
      <form>
        <div className="w-full px-3 my-1">
          <Typography component="legend">Rate it</Typography>
          <Rating
            name="size-small"
            value={rating}
            size="small"
            onChange={(event, newValue) => setRating(newValue)}
          />

          <textarea
            className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
            name="body"
            placeholder="Type Your Comment"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="w-full flex justify-end px-3">
          <input
            type="button"
            className="px-2.5 py-1.5 rounded-md text-white text-sm bg-indigo-500"
            value="Submit"
            onClick={handleSubmitComment}
          />
        </div>
      </form>
    </div>
  );
};

export default DiscussionFestivities;
