import React, { useEffect, useRef, useState, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import mapboxAccessToken from "../data/mapboxAccessToken";
import axios from "axios";
import { Button } from "@mui/material";

function ItineraryMap({ itinerary }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapContainerRef = useRef(null);
  const [travelInfo, setTravelInfo] = useState({ time: 0, distance: 0 });
  const geolocateControlRef = useRef(null);
  const customControlRef = useRef(null);

  const toggleFullScreen = () => {
    const mapElement = mapContainerRef.current;
    if (!isFullScreen) {
      mapElement.requestFullscreen(); // Request full screen for the map container
    }
    setIsFullScreen(!isFullScreen); // Update the full screen state
  };

  const updateCustomControlContent = useCallback(() => {
    if (customControlRef.current) {
      customControlRef.current.innerHTML = `<b>ESTIMATED ONLY:</b> <br/> <b>Travel Time:</b> ${travelInfo.time}<br><b>Distance:</b> ${travelInfo.distance}`;
    }
  }, [travelInfo.time, travelInfo.distance]);

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    const bounds = [
      [116.928977, 4.587203],
      [126.611043, 21.321048],
    ];

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/xsend/clk9t69hj001501q98ucibq70",
      center: [121.41804992973309, 14.268459088733751],
      zoom: 10,
      maxBounds: bounds,
      pitch: 50,
      width: "100%",
      height: "100%",
    });

    let animationId; // Declare animationId here

    map.on("load", () => {
      if (itinerary?.length > 0) {
        itinerary.forEach((place) => {
          console.log("Coordinates:", place.coordinates);
          // Create a custom marker element with the image
          const customMarker = document.createElement("div");
          customMarker.className = "custom-marker";
          customMarker.style.backgroundImage = `url(${place.imageSrc})`;
          customMarker.style.backgroundSize = "cover";
          customMarker.style.width = "30px";
          customMarker.style.height = "30px";
          customMarker.style.borderRadius = "50%";
          customMarker.style.cursor = "pointer";

          const popupContent = document.createElement("div");
          popupContent.innerHTML = `<h5>${place.name}</h5>`;

          // const goButton = document.createElement("button");
          // goButton.innerHTML = "Go";
          // goButton.style.backgroundColor = "blue"; // Red color
          // goButton.style.color = "white"; // Text color
          // goButton.style.padding = "8px 16px"; // Padding for the button
          // goButton.style.border = "none"; // Remove border
          // goButton.style.borderRadius = "4px"; // Rounded corners
          // goButton.style.cursor = "pointer"; // Cursor on hover
          // goButton.onclick = () => {
          //   // Add functionality for the "Go" button here
          //   console.log("Go button clicked for:", place.name);
          // };

          // popupContent.appendChild(goButton);

          const popup = new mapboxgl.Popup().setDOMContent(popupContent);

          // Add the custom marker to the map
          const marker = new mapboxgl.Marker({ element: customMarker })
            .setLngLat(place.coordinates)
            .addTo(map);

          marker.setPopup(popup);
        });

        const coordinates = itinerary.map((place) => place.coordinates);

        axios
          .get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates.join(
              ";"
            )}?geometries=geojson&steps=true&access_token=${
              mapboxgl.accessToken
            }`
          )
          .then((response) => {
            const route = response.data.routes[0].geometry;
            map.addSource("route", {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: route,
              },
            });

            map.addLayer({
              id: "line-background",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "blue",
                "line-width": 6,
                "line-opacity": 0.4,
              },
            });

            map.addLayer({
              id: "line-dashed",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "blue",
                "line-width": 6,
                "line-dasharray": [0, 4, 3],
              },
            });

            const dashArraySequence = [
              [0, 4, 3],
              [0.5, 4, 2.5],
              [1, 4, 2],
              [1.5, 4, 1.5],
              [2, 4, 1],
              [2.5, 4, 0.5],
              [3, 4, 0],
              [0, 0.5, 3, 3.5],
              [0, 1, 3, 3],
              [0, 1.5, 3, 2.5],
              [0, 2, 3, 2],
              [0, 2.5, 3, 1.5],
              [0, 3, 3, 1],
              [0, 3.5, 3, 0.5],
            ];

            let step = 0;

            function animateDashArray(timestamp) {
              try {
                if (
                  map.getLayer("line-dashed") !== undefined &&
                  map.isSourceLoaded("route")
                ) {
                  const newStep = parseInt(
                    (timestamp / 50) % dashArraySequence.length
                  );

                  if (newStep !== step) {
                    map.setPaintProperty(
                      "line-dashed",
                      "line-dasharray",
                      dashArraySequence[step]
                    );
                    step = newStep;
                  }
                }
              } catch (error) {
                console.error("Error in animateDashArray:", error);
              }

              animationId = requestAnimationFrame(animateDashArray);
            }

            // Ensure the map is fully loaded before starting the animation
            map.on("idle", () => {
              animationId = requestAnimationFrame(animateDashArray);
            });

            const timeInSeconds = response.data.routes[0].duration;
            const distanceInMeters = response.data.routes[0].distance;

            let time, distance;

            if (timeInSeconds < 3600) {
              time = (timeInSeconds / 60).toFixed(0) + " minutes";
            } else {
              time = (timeInSeconds / 3600).toFixed(0) + " hours";
            }

            if (distanceInMeters < 1000) {
              distance = distanceInMeters + " meters";
            } else {
              distance = (distanceInMeters / 1000).toFixed(1) + " kilometers";
            }

            setTravelInfo({ time, distance });
          })
          .catch((error) => {
            console.error("Error fetching route:", error);
          });
      }
    });

    map.addControl(new mapboxgl.FullscreenControl());

    // Initialize the user location control
    geolocateControlRef.current = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    map.addControl(geolocateControlRef.current, "bottom-left");

    // Function to handle geolocation updates
    function handleGeolocationUpdate(e) {
      if (e.coords) {
        const longitude = e.coords.longitude;
        const latitude = e.coords.latitude;
        const heading = e.coords.heading;

        const currentLocation = [longitude, latitude];

        const isHeadingAccurate =
          heading !== null && heading >= 0 && heading < 360;

        map.easeTo({
          center: [longitude, latitude],
          bearing: isHeadingAccurate ? heading : 0,
          pitch: 60,
          zoom: 10,
          duration: 2000,
        });

        if (itinerary?.length > 0) {
          const firstPointCoordinates = itinerary[0].coordinates;

          axios
            .get(
              `https://api.mapbox.com/directions/v5/mapbox/driving/${currentLocation[0]},${currentLocation[1]};${firstPointCoordinates[0]},${firstPointCoordinates[1]}?geometries=geojson&steps=true&access_token=${mapboxgl.accessToken}`
            )
            .then((response) => {
              const route = response.data.routes[0].geometry;
              map.addSource("currentRoute", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: route,
                },
              });

              map.addLayer({
                id: "current-route",
                type: "line",
                source: "currentRoute",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "blue",
                  "line-width": 6,
                  "line-opacity": 0.6,
                },
              });
              
            })
            .catch((error) => {
              console.error("Error fetching current route:", error);
            });
        }
      }
    }

    // Event listener for geolocation updates
    geolocateControlRef.current.on("geolocate", (e) =>
      handleGeolocationUpdate(e)
    );

    customControlRef.current = document.createElement("div");
    customControlRef.current.style.position = "absolute";
    customControlRef.current.style.top = "10px";
    customControlRef.current.style.left = "10px";
    customControlRef.current.style.background = "white";
    customControlRef.current.style.padding = "10px";
    customControlRef.current.style.border = "1px solid #ccc";
    customControlRef.current.style.borderRadius = "5px";
    customControlRef.current.style.fontSize = "14px";

    updateCustomControlContent();

    map.getContainer().appendChild(customControlRef.current);

    // Make sure to cancel the animation when the component unmounts
    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
      map.remove();
    };
  }, [itinerary, updateCustomControlContent]);

  useEffect(() => {
    updateCustomControlContent();
  }, [travelInfo, updateCustomControlContent]);

  return (
    <div>
      <div ref={mapContainerRef}></div>
      <Button variant="contained" onClick={toggleFullScreen}>
        Show Route
      </Button>
    </div>
  );
}

export default ItineraryMap;
